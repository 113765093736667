/* eslint eqeqeq: 0 */
import moment from 'moment';
import logo from '../../assets/images/buildcard-xs.svg';
import { BUILD_CARDS, SPACES_SHOW } from '../../shared/util/constant';

export const todoAndReleaseOption = (stats) => ({
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
    spacing: [-15, 0, 10, 0],
    height: '310px',
  },
  legend: {
    verticalAlign: 'bottom',
    itemStyle: {
      fontSize: '1.15rem',
    },
  },
  title: {
    text: '',
  },
  credits: false,
  colors: ['#B388FF', '#6200EA'],
  tooltip: {
    followPointer: true,
    outside: true,
    useHTML: true,
    formatter: function (tooltip) {
      return `<div class="chart-tooltip budget-bargraph-tooltip">
          <div class="body">
            <div class="content-row">
              <div class="count"><span>Overdue: ${this?.point?.y} ${this.point.name}</div>
            </div>
          </div>
        </div>`;
    },
    positioner: (w, h, p) => {
      return {
        x: p?.plotX - 160,
        y: p?.plotY - 60,
      };
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      borderWidth: 0,
      fillColor: '#ECEDF0',
      borderRadius: 0,
    },
  },
  series: [
    {
      type: 'pie',
      innerSize: '65%',
      data: (() => {
        let arr = [];
        if (stats?.todosReleases?.total_pending_todos) {
          arr.push({
            name: "To-do's",
            y: stats?.todosReleases?.total_pending_todos ?? 0,
          });
        }
        if (stats?.todosReleases?.total_pending_release) {
          arr.push({
            name: 'Releases Review',
            y: stats?.todosReleases?.total_pending_release ?? 0,
          });
        }
        return arr;
      })(),
      states: {
        inactive: {
          enabled: false,
        },
      },
    },
  ],
  accessibility: {
    enabled: false,
  },
});

const generateDistributionLegends = (stats) => {
  let arr = [];
  if (stats?.buildcardDistribution?.draft) {
    arr.push({
      name: 'Draft',
      y: stats?.buildcardDistribution?.draft,
    });
  }
  if (stats?.buildcardDistribution?.pending_approval) {
    arr.push({
      name: 'Pending approval',
      y: stats?.buildcardDistribution?.pending_approval,
    });
  }
  if (stats?.buildcardDistribution?.ready_for_kickoff) {
    arr.push({
      name: 'Ready for kick off',
      y: stats?.buildcardDistribution?.ready_for_kickoff,
    });
  }
  if (stats?.buildcardDistribution?.starting_soon) {
    arr.push({
      name: 'Starting soon',
      y: stats?.buildcardDistribution?.starting_soon,
    });
  }
  if (stats?.buildcardDistribution?.running) {
    arr.push({
      name: 'Running',
      y: stats?.buildcardDistribution?.running,
    });
  }
  if (stats?.buildcardDistribution?.completed) {
    arr.push({
      name: 'Delivered',
      y: stats?.buildcardDistribution?.completed,
    });
  }
  return arr;
};

export const buildCardDistributionOption = (stats) => ({
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
    height: '240px',
    spacing: [0, 0, 0, 0],
  },
  legend: {
    itemStyle: {
      fontSize: '11px',
    },
  },
  title: {
    text: '',
  },
  credits: false,
  colors: ['#F3EBFF', '#B388FF', '#7C4DFF', '#651FFF', '#6200EA', '#69F0AE'],
  tooltip: {
    followPointer: true,
    outside: true,
    useHTML: true,
    formatter: function (tooltip) {
      return `<div class="chart-tooltip">
          <div class="body">
            <div class="content-row">
              <div class="count">${this.point.name}: ${this?.point?.y}</div>
            </div>
        </div>
      </div>`;
    },
    positioner: function (w, h, p) {
      return {
        x: p?.plotX - 150,
        y: p?.plotY - 60,
      };
    },
  },
  plotOptions: {
    pie: {
      // size: 160,
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      borderWidth: 0,
      fillColor: '#ECEDF0',
      borderRadius: 0,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
  series: [
    {
      type: 'pie',
      innerSize: '70%',
      data: generateDistributionLegends(stats),
      states: {
        inactive: {
          enabled: false,
        },
      },
    },
  ],
  accessibility: {
    enabled: false,
  },
});

export const trendingReleaseOption = (stats) => ({
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
    height: '280px',
  },
  title: {
    text: '',
  },
  subtitle: {
    text: '',
  },
  tooltip: {
    outside: false,
    shared: true,
    fontSize: 12,
    useHTML: true,
    formatter: function (tooltip) {
      return `<div class='graph-tooltip-wrap'>
        <div class="graph-tooltip-content">
          <div class="date-text">
            ${this.points[0]?.key}
          </div>
          <div class="tickets-row">
            <span class="title">Tickets</span>
            ${this.points?.[0]?.series?.name == 'Raised' ? `<span class="tickets-count" style="font-weight: 500;">${this.points?.[0]?.y}</span>` : ''}
            ${this.points?.[0]?.series?.name == 'Resolved' || this.points?.[1]?.series?.name == 'Resolved' ? `<span class="tickets-resolved" style="color:#6200EA; font-weight: 500;"><em class="icon be-down-arrow-1"></em> ${this.points?.[1]?.y ?? this.points?.[0]?.y} Resolved</span>` : ''}
          </div>
        </div>
      </div>`;
    },
  },
  xAxis: {
    lineColor: '#333333',
    lineWidth: 1,
    type: 'datetime',
    categories: stats?.ticketTrends?.map((item) => Object.keys(item)),
    crosshair: {
      color: '#C0C3CE',
      width: '1px',
      interpolate: true,
      snap: true,
    },
    labels: {
      style: { fontSize: '10px' },
    },
  },
  yAxis: {
    max: 100,
    title: {
      text: '',
    },
    offset: 10,
  },
  legend: {
    symbolRadius: 2,
    squareSymbol: true,
    padding: 5,
    enabled: false,
    align: 'right',
    verticalAlign: 'top',
    itemStyle: {
      color: 'black',
      fontSize: '12px',
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      pointPlacement: 'on',
      fillOpacity: 0.1,
    },
    area: {
      marker: {
        radius: 2,
      },
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      threshold: null,
    },
  },
  series: [
    {
      type: 'area',
      name: 'Raised',
      data: stats?.ticketTrends?.map((item) =>
        Object.keys(item).map((val) => item[val]['created_todos_count'])
      ),
      color: '#EF7C8B',
    },
    {
      type: 'area',
      name: 'Resolved',
      data: stats?.ticketTrends?.map((item) =>
        Object.keys(item).map((val) => item[val]['completed_todos_count'])
      ),
      color: '#6200EA',
    },
  ],
  accessibility: {
    enabled: false,
  },
});

const generateLegends = (stats) => {
  let arr = [];
  if (stats?.not_started) {
    arr.push({
      name: 'Not Started',
      y: stats?.not_started,
      color: '#DFE0E6',
    });
  }
  if (stats?.on_track) {
    arr.push({
      name: 'On Track',
      y: stats?.on_track,
      color: '#69F0AE',
    });
  }
  if (stats?.progressing) {
    arr.push({
      name: 'Progressing',
      y: stats?.progressing,
      color: '#FFD740',
    });
  }
  if (stats?.off_track) {
    arr.push({
      name: 'Off Track',
      y: stats?.off_track,
      color: '#FF8A80',
    });
  }
  if (stats?.accomplished) {
    arr.push({
      name: 'Accomplished',
      y: stats?.accomplished,
      color: '#00C853',
    });
  }
  return arr;
};

export const goalsStatusData = (stats, height, showInLegend, showTitle) => ({
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
    height: height,
    spacing: [0, 15, 0, 15],
    custom: {},
    events: {
      render() {
        const series = this.series[0];
        let customLabel = this.options.chart.custom.label;

        const x = series.center[0] + this.plotLeft,
          y = series.center[1] + this.plotTop - customLabel.attr('height') / 2;

        customLabel.attr({
          x,
          y,
        });
        // Set font size based on chart diameter
        customLabel.css({
          fontSize: `${series.center[2] / 12}px`,
        });
      },
      load() {
        let customLabel = this.options.chart.custom.label;

        if (!customLabel) {
          this.options.chart.custom.label = this.renderer
            ?.label(
              `<div style="font-size:14px;color:#262626; font-weight: bold, text-align: center;"><strong>${showTitle ? (stats?.total_count ?? 0) + '</strong> KPIs' : ''}</div>`
            )
            .css({
              color: '#676B7E',
              textAnchor: 'middle',
            })
            .add();
        }
      },
    },
  },
  legend: {
    align: 'center',
    itemStyle: {
      fontSize: '11px',
    },
  },
  title: {
    text: '',
  },
  credits: false,
  tooltip: {
    followPointer: true,
    outside: true,
    useHTML: true,
    formatter: function (tooltip) {
      return `<div class="chart-tooltip budget-bargraph-tooltip">
      <div class="body">
        <div class="content-row">
          <span style="font-size:1.2rem;" >${this.point.name}: <strong>${this?.point?.y}</strong></span>
        </div>
          </div>
      </div>`;
    },
    positioner: function (w, h, p) {
      return {
        x: p?.plotX - 140,
        y: p?.plotY - 40,
      };
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      showInLegend: showInLegend,
      borderWidth: 0,
      fillColor: '#ECEDF0',
      borderRadius: 0,
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
  series: [
    {
      type: 'pie',
      innerSize: '65%',
      data: generateLegends(stats),
      states: {
        inactive: {
          enabled: false,
        },
      },
    },
  ],
  accessibility: {
    enabled: false,
  },
});

const calculateMaxTimeDifference = (data) => {
  if (!data?.length) {
    return 0;
  }

  let oldestStartDate = data[0]?.low;
  let farthestEndDate = data[0]?.high;

  data.forEach((obj) => {
    if (obj.low < oldestStartDate) {
      oldestStartDate = obj.low;
    }
    if (obj.high > farthestEndDate) {
      farthestEndDate = obj.high;
    }
  });

  const daysInBetween = moment(farthestEndDate).diff(
    moment(oldestStartDate),
    'days'
  );
  return Math.max(daysInBetween, 30);
};

export const goalsTimeLine = (data, height = '350px') => {
  const maxTimeDiff = calculateMaxTimeDifference(data);
  const CHARACTERS_IN_MAX_TIME_DIFF = 85;
  return {
    chart: {
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      type: 'columnrange',
      inverted: true,
      height: height,
      spacing: [-7, 0, 0, 5],
      events: {
        render: function () {
          if (data?.some((item) => item?.color !== '#ffffff')) {
            setTimeout(() => {
              let yAxis = this.yAxis?.[0];
              let coords = yAxis?.toPixels(new Date());
              this.renderer
                ?.path(['M', coords, 0, 'L', coords, height])
                .attr({
                  'stroke-width': 2.5,
                  stroke: '#B388FF',
                  zIndex: 1,
                })
                .add();

              this.renderer
                ?.symbol('circle', coords - 5.5, 0, 12, 12)
                .attr({
                  fill: '#B388FF',
                })
                .add();
            }, 1000);
          }
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      // categories: uniqueColors,
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      tickWidth: 0,
      gridLineDashStyle: 'ShortDash',
      gridLineWidth: 2,
      zIndex: 0,
    },
    yAxis: {
      type: 'datetime',
      tickInterval: 35 * 24 * 3600 * 1000,
      title: {
        text: '',
      },
      labels: {
        format: '{value:%d %b, %Y}',
        style: {
          fontSize: '10px',
        },
      },
      opposite: true,
      gridLineDashStyle: 'Solid',
      grid: {
        enabled: true,
        borderWidth: 2,
      },
      gridLineWidth: 50,
      gridLineColor: '#F3EBFF',
      gridZIndex: 0.4,
    },
    credits: false,
    tooltip: {
      enabled: true,
      formatter: function (val) {
        let diff = Math.max(
          moment(this.point.high).diff(moment(this.point.low), 'days'),
          1
        );
        let charLen = this.point?.kpi_name?.trim()?.length;
        if (charLen / diff <= CHARACTERS_IN_MAX_TIME_DIFF / maxTimeDiff) {
          return false;
        } else {
          return `<span>${this.point?.kpi_name?.trim()}</span>`;
        }
      },
      positioner: function (labelWidth, _, point) {
        return {
          x: point.plotX - 50,
          y: point.plotY + 20,
        };
      },
      borderWidth: 1,
      backgroundColor: 'rgba(255,255,255,0.8)',
      style: {
        color: '#3C3E49',
        fontSize: '10px',
      },
    },
    plotOptions: {
      columnrange: {
        borderRadius: '50%',
        pointWidth: 18,
        dataLabels: {
          inside: true,
          enabled: true,
          useHTML: false,
          verticalAlign: 'middle',
          align: 'center',
          color: '#3C3E49',
          style: {
            fontWeight: 'normal',
            fontSize: '12px',
            textOverflow: 'ellipsis',
            textOutline: '#3C3E49',
            strokeWidth: 0,
            overflow: 'none',
          },
          formatter: function (val) {
            let diff = Math.max(
              moment(this.point.high).diff(moment(this.point.low), 'days'),
              1
            );
            let charLen = this.point?.kpi_name?.trim()?.length;
            if (charLen / diff <= CHARACTERS_IN_MAX_TIME_DIFF / maxTimeDiff) {
              return `<span>${this.point?.kpi_name?.trim()}</span>`;
            } else {
              return '';
            }
          },
        },
      },
    },

    legend: {
      enabled: false,
    },
    series: [
      {
        name: '',
        data: data,
        borderColor: '#ffffff',
      },
    ],
    accessibility: {
      enabled: false,
    },
  };
};

export const creditsGraph = (data, props) => {
  return {
    chart: {
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      type: 'bar',
      height: props.height,
      minWidth: '100%',
      spacing: [0, 0, 0, 0],
    },
    plotOptions: {
      series: {
        stacking: 'percent',
        grouping: false,
        pointWidth: props.pointWidth,
      },
    },
    title: {
      text: '',
    },
    legend: {
      symbolRadius: 0,
      align: 'left',
      margin: 0,
      reversed: true,
      useHTML: true,
      labelFormatter: function () {
        let num = this.name.match(/[\d.]+/)[0];
        return `<span style="font-weight: bold">${Math.round(num)}</span> ${this.name.replace(num, '')}`;
      },
      itemStyle: {
        fontSize: props.fontSize,
        fontFamily: 'Rubik, sans-serif',
      },
    },
    tooltip: {
      enabled: false,
      outside: false,
    },
    xAxis: {
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      tickWidth: 0,
      gridLineWidth: 0,
    },
    yAxis: {
      max: 100,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      lineWidth: 0,
      tickWidth: 0,
      gridLineWidth: 0,
    },
    credits: false,
    series: data,
    accessibility: {
      enabled: false,
    },
  };
};

export const creditAllocationGraphData = (stats) => {
  return {
    chart: {
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      height: '210px',
      spacing: [0, 0, 0, 0],
    },
    legend: {
      itemStyle: {
        fontSize: '12px',
      },
      layout:
        stats?.allocated_space_credits?.length < 5 ? 'vertical' : 'horizontal',
      align: 'left',
      verticalAlign: 'middle',
      borderWidth: 0,
      width: 280,
      itemWidth: 140,
      itemMarginBottom: 13,
    },
    title: {
      text: '',
    },
    credits: false,
    colors: stats?.allocated_space_credits?.map((item) => item?.space?.color),
    tooltip: {
      padding: 0,
      borderRadius: 4,
      followPointer: false,
      outside: true,
      useHTML: true,
      stickOnContact: true,
      formatter: function (tooltip) {
        let goal = stats?.allocated_space_credits.find(
          (item) => item?.space?.name == this?.point?.name
        );
        return `<div class="chart-tooltip">
            <div class="head">${this.point.name}</div>
            <div class="body">
              <div class="content-row">
                <div class="title">Credit allocated: </div>
                <div class="count">${Math.round(this.point.y)}</div>
              </div>
              ${
                goal?.space?.space_goals?.length
                  ? `<div class="content-row">
                  <div class="title">Goal: </div>
                  <div class="tag-wrapper">
                    ${goal?.space?.space_goals?.map((item, idx) => `<span class="custom-tag">${item?.['name']}</span>`)}
                  </div>
              </div>`
                  : ''
              }
            </div>
          </div>
          `;
      },
      positioner: function (labelWidth, _, point) {
        const chart = this.chart;

        return {
          x:
            point.plotX < chart.plotWidth / 2
              ? point.plotX * 1.5 // half left
              : point.plotX * 2, // half right
          y:
            point.plotY < chart.plotHeight / 2
              ? point.plotY / 0.8 // top half
              : point.plotY * 1.2, // bottom half
        };
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        borderWidth: 2,
        fillColor: '#ECEDF0',
        borderRadius: 1,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        innerSize: '62%',
        data: stats?.allocated_space_credits?.map((item) => ({
          name: item?.space?.name,
          y: item?.allocated_credits,
        })),
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
    ],
    accessibility: {
      enabled: false,
    },
  };
};

export const creditConsumptionGraph = (data) => {
  let finalData = Object.entries(data || {}).map((entry) => {
    let finalObj = {};
    return entry.reduce((newObj, item: any) => {
      if (typeof item == 'string') {
        finalObj = {
          ...finalObj,
          month: item?.split(' ')[0],
          year: item?.split(' ')[1],
        };
      } else {
        finalObj = { ...finalObj, val: item };
      }
      return finalObj;
    }, {});
  });
  const Months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (finalData?.length) {
    finalData.sort((a: any, b: any) => {
      if (a.year !== b.year) return a.year - b.year;
      return Months.indexOf(a.month) - Months.indexOf(b.month);
    });
  }

  return {
    chart: {
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      type: 'column',
      height: '210px',
      spacing: [10, 0, 15, 0],
      scrollablePlotArea: {
        minWidth: finalData?.length * 75,
      },
    },
    xAxis: {
      categories: finalData?.map((item: any) => `${item?.month} ${item?.year}`),
    },
    yAxis: {
      title: {
        text: null,
      },
      // tickInterval: 250
    },
    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      outside: true,
      formatter: function (tooltip) {
        return `<div class="chart-tooltip" style="padding: 5px"><strong>${this?.point?.category}: ${this?.point?.y}</strong></div>`;
      },
    },
    credits: false,
    plotOptions: {
      column: {
        pointWidth: 25,
        borderRadius: {
          radius: 13,
        },
        color: '#7C4DFF',
        grouping: false,
        borderRadiusTopLeft: 10,
        borderRadiusTopRight: 10,
      },
    },
    series: [
      {
        data: finalData?.map((item: any) => item?.val),
      },
    ],
    accessibility: {
      enabled: false,
    },
  };
};

export const buildcardConsumptionGraphData = (stats) => {
  let originalColor;
  return {
    chart: {
      type: 'bar',
      spacing: [0, 0, 0, 150],
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      scrollablePlotArea: {
        minHeight: stats?.build_cards?.length * 50,
      },
      height:
        stats?.total_build_cards_count <= 5
          ? stats?.total_build_cards_count * 80
          : null,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: stats?.build_cards?.map((bc) => bc?.name),
      labels: {
        verticalAlign: 'center',
        align: 'left',
        textAlign: 'left',
        useHTML: true,
        x: -125,
        style: {
          fontSize: '1.2rem',
          color: '#676B7E',
        },
        formatter: function () {
          return `<div style="display: flex; align-items: center;"><img
            src=${logo}
            alt="Builder icon"
          /><span style="margin-left: 3px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 105px;">${this.value}</span><div>
          `;
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      lineWidth: 1,
    },
    tooltip: {
      outside: true,
      useHTML: true,
      shared: true,
      formatter: function (tooltip) {
        return `<div class="chart-tooltip credits-info-tooltip" >
            <div class="body">
              <div class="credits-info-header"><img
                src=${logo}
                alt="Builder icon"
              />${this?.x}</div>
              <div class="credits-info-tooltip">
                <ul>
                  <li>
                    <span>Total Credit Used: </span>
                    <strong class="truncated-text">${stats?.build_cards?.find((item) => item?.name == this.x)?.consumed_credits}</strong>
                  </li>
                  <li>
                    <span>Status: </span>
                    <strong class="chip on_track">Ready for kick off</strong>
                  </li>
                  <li>
                    ${stats?.build_cards?.find((item) => item?.name == this.x)?.space_goals?.length > 0 ? '<span>Goal:</span>' : ''}
                   <div class="chip-outer-wrap">${stats?.build_cards?.find((item) => item?.name == this.x)?.space_goals?.map((goal) => `<strong class="chip">${goal?.name}</strong>`)}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>`;
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        point: {
          events: {
            mouseOver: function () {
              originalColor = this.color;

              this.update({
                color: '#6200EA',
              });
            },
            mouseOut: function () {
              this.update({
                color: originalColor,
              });
            },
          },
        },
      },
    },
    credits: false,
    series: [
      {
        color: '#B388FF',
        borderColor: '#B388FF',
        shadow: {
          color: '#E2E2E2',
          offsetX: -5,
          offsetY: -1,
          opacity: 0.2,
          width: 5,
        },
        pointWidth: 25,
        data: stats?.build_cards?.map((bc) => bc?.consumed_credits),
      },
    ],
    accessibility: {
      enabled: false,
    },
  };
};

export const semiDonutChart = (data?) => {
  const getGraphDataAccState = () => {
    if (data?.state == 'draft' || data?.state == 'pending_approval') {
      return { text: 'CREDITS NEEDED', cost: data?.finalCost };
    } else {
      return { text: 'CREDITS USED', cost: data?.usedCredits };
    }
  };

  return {
    chart: {
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      marginTop: 35,
      marginBottom: 0,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: '125px',
      backgroundColor: null,
    },
    title: {
      text: `<span><strong>${Math.round(getGraphDataAccState().cost)}/${Math.round(data?.creditBalance)}</strong></br>${getGraphDataAccState().text}</span>`,
      useHtml: true,
      align: 'center',
      verticalAlign: 'middle',
      y: 25,
      style: {
        fontSize: data?.currencyName != 'credits' ? '1.1rem' : '1.4rem',
        fontWeight: 'normal',
      },
    },
    credits: false,
    tooltip: {
      enabled: false,
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '75%'],
        size: '310%',
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
        borderWidth: 0,
        fillColor: '#B388FF',
        borderRadius: 0,
      },
    },
    series: [
      {
        type: 'pie',
        name: '',
        innerSize: '80%',
        data: [
          {
            name: 'name1',
            y: getGraphDataAccState().cost,
            color:
              getGraphDataAccState().cost > data?.creditBalance
                ? '#FF5546'
                : '#6200EA',
          },
          {
            name: 'name2',
            y:
              getGraphDataAccState().cost > data?.creditBalance
                ? 0
                : data?.creditBalance - getGraphDataAccState().cost,
            color: '#B388FF',
          },
        ],
      },
    ],
    accessibility: {
      enabled: false,
    },
  };
};

function createDataPoint(stats) {
  let arr = [];
  if (stats?.draft) {
    arr.push({
      name: 'Draft',
      y: stats?.draft,
    });
  }
  if (stats?.pending_approval) {
    arr.push({
      name: 'Pending approval',
      y: stats?.pending_approval,
    });
  }
  if (stats?.ready_for_kickoff) {
    arr.push({
      name: 'Ready for kick off',
      y: stats?.ready_for_kickoff,
    });
  }
  if (stats?.starting_soon) {
    arr.push({
      name: 'Starting soon',
      y: stats?.starting_soon,
    });
  }
  if (stats?.running) {
    arr.push({
      name: 'Running',
      y: stats?.running,
    });
  }
  if (stats?.completed) {
    arr.push({
      name: 'Delivered',
      y: stats?.completed,
    });
  }
  return arr;
}

export const cioGraphPieOption = (stats) => ({
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
    height: '280rem',
    spacing: [0, 0, 0, 0],
    custom: {},
    events: {
      render() {
        const series = this.series[0];
        let customLabel = this.options.chart.custom.label;

        if (!customLabel) {
          customLabel = this.options.chart.custom.label = this.renderer
            .label(
              `<span style="font-size: 12px;line-height:13px;font-weight:500;color:#3C3E49;">${Math.round(stats?.build_cards_count) || 0}</span>` +
                '<br/><span style="font-size: 9px;line-height:11px;letter-spacing:0.068rem;color:#3C3E49;">TOTAL</span><br/>' +
                '<span style="font-size: 9px;line-height:11px;letter-spacing:0.068rem;color:#3C3E49;">BUILDCARDS</span>'
            )
            .css({
              color: '#676B7E',
              textAnchor: 'middle',
            })
            .add();
        }

        const x = series.center[0] + this.plotLeft,
          y = series.center[1] + this.plotTop - customLabel.attr('height') / 2;

        customLabel.attr({
          x,
          y,
        });
        // Set font size based on chart diameter
        customLabel.css({
          fontSize: `${series.center[2] / 12}px`,
        });
      },
    },
  },
  legend: {
    alignColumns: false,
    itemStyle: {
      fontSize: '11px',
    },
  },
  title: {
    text: '',
  },
  credits: false,
  colors: ['#DFE0E6', '#FFD740', '#7C4DFF', '#FFAB00', '#00E676', '#B9F6CA'],
  tooltip: {
    followPointer: true,
    outside: false,
    useHTML: true,
    formatter: function (tooltip) {
      return this?.point?.y
        ? `<div class="chart-tooltip budget-bargraph-tooltip">
          <div class="body">
            <div class="content-row">
              <div class="count"><span style="font-weight:normal">${this.point.name}<span>: <strong>${this?.point?.y}<strong></div>
            </div>
          </div>
        </div>`
        : null;
    },
  },
  plotOptions: {
    pie: {
      // size: 170,
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      borderWidth: 1,
      borderColor: '#FFFFFF',
      fillColor: '#ECEDF0',
      borderRadius: 0,
      shadow: {
        opacity: 0.8,
        color: '#CCC',
      },
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          enabled: false,
        },
      },
    },
  },
  series: [
    {
      type: 'pie',
      innerSize: '75%',
      data: createDataPoint(stats),
    },
  ],
  accessibility: {
    enabled: false,
  },
});

function createHealthCheckPoint(stats) {
  let arr = [];
  if (stats?.on_track) {
    arr.push({
      name: 'On Track',
      y: stats?.on_track,
    });
  }
  if (stats?.off_track) {
    arr.push({
      name: 'Off Track',
      y: stats?.off_track,
    });
  }
  if (stats?.at_risk) {
    arr.push({
      name: 'At Risk',
      y: stats?.at_risk,
    });
  }
  return arr;
}

export const cioHealthPieGraphOption = (stats) => ({
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
    height: '260rem',
    spacing: [0, 0, 0, 0],
    custom: {},
    events: {
      render() {
        const series = this.series[0];
        let customLabel = this.options.chart.custom.label;

        if (!customLabel) {
          customLabel = this.options.chart.custom.label = this.renderer
            .label(
              `<span style="font-size: 12px;line-height:13px;font-weight:500;color:#3C3E49;">${Math.round(stats?.build_cards_count) || 0}</span>` +
                '<br/><span style="font-size: 9px;line-height:11px;letter-spacing:0.068rem;color:#3C3E49;">ONGOING</span><br/>' +
                '<span style="font-size: 9px;line-height:11px;letter-spacing:0.068rem;color:#3C3E49;">BUILDCARDS</span>'
            )
            .css({
              color: '#676B7E',
              textAnchor: 'middle',
            })
            .add();
        }

        const x = series.center[0] + this.plotLeft,
          y = series.center[1] + this.plotTop - customLabel.attr('height') / 2;

        customLabel.attr({
          x,
          y,
        });
        // Set font size based on chart diameter
        customLabel.css({
          fontSize: `${series.center[2] / 12}px`,
        });
      },
    },
  },
  legend: {
    alignColumns: false,
    itemStyle: {
      fontSize: '11px',
    },
  },
  title: {
    text: '',
  },
  credits: false,
  colors: ['#00C853', '#FFAB00', '#FF8A80'],
  tooltip: {
    followPointer: true,
    outside: false,
    useHTML: true,
    formatter: function (tooltip) {
      return `<div class="chart-tooltip budget-bargraph-tooltip">
          <div class="body">
            <div class="content-row">
              <div class="count"><span style="font-weight:normal">${this.point.name}<span>: <strong>${this?.point?.y}<strong></div>
            </div>
          </div>
        </div>`;
    },
  },
  plotOptions: {
    pie: {
      // size: 170,
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      borderWidth: 1,
      borderColor: '#FFFFFF',
      fillColor: '#ECEDF0',
      borderRadius: 0,
      shadow: {
        opacity: 0.8,
        color: '#CCC',
      },
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          enabled: false,
        },
      },
    },
  },
  series: [
    {
      type: 'pie',
      innerSize: '75%',
      data: createHealthCheckPoint(stats),
    },
  ],
  accessibility: {
    enabled: false,
  },
});

export const budgetSpendingGraph = (stats) => {
  const paddingFunc = () => {
    // stats?.length > 2 ? 0.32 : 0.456
    if (stats.length == 1) {
      return 0.46;
    } else if (stats.length == 2) {
      return 0.4;
    } else if (stats.length <= 4) {
      return 0.35;
    } else if (stats.length > 4) {
      return 0.28;
    } else {
      return 0.456;
    }
  };

  let labelStyle = {
    style: {
      color: '#3C3E49',
      fontSize: '12px',
    },
  };
  let disableHover = {
    hover: {
      enabled: false,
    },
    inactive: {
      opacity: 1,
    },
  };

  return {
    chart: {
      type: 'column',
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      scrollablePlotArea: {
        minWidth: stats?.length * 180,
      },
    },
    scrollbar: {
      enabled: false,
      buttonBackgroundColor: 'white',
      buttonBorderColor: 'white',
      buttonArrowColor: 'white',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    credits: false,
    xAxis: [
      {
        categories: stats?.map((item) => item?.name),
        crosshair: true,
        labels: labelStyle,
        min: stats?.length > 6 ? 0 : null,
        max: stats?.length - 1,
      },
    ],
    yAxis: [
      {
        labels: labelStyle,
        title: {
          text: '% Increase',
          style: labelStyle.style,
        },
        gridLineDashStyle: 'ShortDash',
        gridLineWidth: 2,
        opposite: true,
      },
      {
        title: {
          text: 'Credits',
          style: labelStyle.style,
        },
        labels: labelStyle,
        gridLineDashStyle: 'ShortDash',
        gridLineWidth: 2,
        lineWidth: 1,
        lineColor: '#3C3E49',
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      outside: true,
      hideDelay: 2000,
      shadow: {
        opacity: 0.05,
      },
      formatter: function (tooltip) {
        const domain = process.env.REACT_APP_DOMAIN.includes('https')
          ? process.env.REACT_APP_DOMAIN
          : `https://${process.env.REACT_APP_DOMAIN}`;

        return `<div class="chart-tooltip budget-bargraph-tooltip">
            <div class="body">
              <div class="content-row">
                <em class='icon be-multiple-users' style="background: ${this.point?.space_color}; color:#ffffff;"></em> 
                <a href='${domain}${SPACES_SHOW.replace(':id', this.point?.space_id)}/overview'>${this.point?.space_name}</a>
              </div>
              <div class="content-row">
                <em class='icon be-buildcard-box'></em><span class="link-text">
                  <a href='${domain}${BUILD_CARDS.replace(':id', this.point?.space_id).replace(':build_card_id', this.point?.bc_id)}'>
                    ${this.point?.bc_name}
                  </a>
                </span>
              </div>
			      </div>
		      </div>`;
      },
      positioner: function (labelWidth, labelHeight, point) {
        return {
          x: point.plotX + 90,
          y: point.plotY - 40,
        };
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          style: labelStyle?.style,
          format: '{y}%',
        },
        enableMouseTracking: false,
        states: disableHover,
      },
      column: {
        stacking: 'normal',
        pointPadding: 0,
        pointWidth: 42,
        states: disableHover,
        groupPadding: paddingFunc(),
        dataLabels: {
          enabled: true,
          verticalAlign: 'top',
          color: '#3C3E49',
          y: -22,
          style: {
            fontSize: '10px',
          },
        },
      },
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      itemMarginRight: 150,
      itemStyle: {
        fontFamily: 'Rubik, sans-serif',
        fontSize: '11px',
        fontWeight: 'normal',
        opacity: 0.8,
      },
    },
    series: [
      {
        name: 'Original budget',
        type: 'column',
        color: '#B388FF',
        stack: 1,
        yAxis: 1,
        data: stats?.map((item) => ({
          bc_name: item?.name,
          space_name: item?.space_name,
          space_color: item?.space_color,
          y: item?.original_credits,
          space_id: item?.space_id,
          bc_id: item?.id,
        })),
      },
      {
        name: 'Increased budget',
        type: 'column',
        color: '#7C4DFF',
        stack: 2,
        yAxis: 1,
        data: stats?.map((item) => ({
          bc_name: item?.name,
          space_name: item?.space_name,
          space_color: item?.space_color,
          y: item?.consumed_credits,
          space_id: item?.space_id,
          bc_id: item?.id,
        })),
      },
      {
        name: '% Increase',
        type: 'line',
        color: '#FFAB00',
        lineWidth: 2,
        data: stats?.map((item) => item?.percentage_increase),
      },
    ],

    accessibility: {
      enabled: false,
    },
  };
};

export const spendAnalysisOption = (stats) => {
  const sortable = Object.entries(stats).sort((a, b) =>
    moment(a[0], 'DD-MM-YYYY').diff(moment(b[0], 'DD-MM-YYYY'))
  );

  return {
    chart: {
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      height: '280px',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    tooltip: {
      outside: false,
      shared: true,
      fontSize: 12,
      useHTML: true,
      formatter: function (tooltip) {
        return `<div class="chart-tooltip">
          <div class="body">
            <div class="content-row">
              <div class="count" style="font-weight:normal">${this.point?.y} credits</div>
            </div>
          </div>
        </div>`;
      },
    },
    xAxis: {
      lineColor: '#333333',
      lineWidth: 1,
      type: 'datetime',
      categories: sortable?.map((item) =>
        moment(item[0], 'YYYY-MM-DD').format('MMM')
      ),
      crosshair: {
        color: '#C0C3CE',
        width: '1px',
        interpolate: true,
        snap: true,
      },
      labels: {
        style: { fontSize: '10px' },
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      offset: 10,
      labels: {
        format: '{value}',
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointPlacement: 'on',
        fillOpacity: 0.1,
      },
      area: {
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    series: [
      {
        type: 'area',
        name: 'Analysis',
        data: sortable?.map((item) => +item[1]),
        lineColor: '#6200EA',
        marker: { symbol: 'circle', radius: 3.5 },
        color: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, '#6200EA'],
            [1, '#EFE6FD'],
          ],
        },
      },
    ],
    accessibility: {
      enabled: false,
    },
  };
};

export const dashboardTimeline = (data, height = '350px') => {
  return {
    chart: {
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      type: 'columnrange',
      inverted: true,
      height: height,
      spacing: [0, 0, 0, 0],
      scrollablePlotArea: {
        minHeight: data?.[0]?.data?.length * 60,
      },
      events: {
        render: function () {
          if (data?.some((item) => item?.color !== '#ffffff')) {
            setTimeout(() => {
              let yAxis = this.yAxis?.[0];
              let coords = yAxis?.toPixels(new Date());
              this.renderer
                ?.path(['M', coords, 25, 'L', coords, '2000rem'])
                .attr({
                  'stroke-width': 2,
                  stroke: '#B388FF',
                  zIndex: 1,
                })
                .add();
            }, 1000);
          }
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      scrollbar: {
        enabled: false,
        buttonBackgroundColor: 'white',
        buttonBorderColor: 'white',
        buttonArrowColor: 'white',
      },
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      tickWidth: 0,
      gridLineDashStyle: 'ShortDash',
      gridLineWidth: 2,
      zIndex: 0,
      min: data?.[0]?.data?.length > 1 ? 0 : null,
      max: data?.[0]?.data?.length - 1,
    },
    yAxis: {
      type: 'datetime',
      tickInterval: 90 * 24 * 3600 * 1000,
      title: {
        text: '',
      },
      labels: {
        format: '{value:%e %b, %Y}',
        style: {
          fontSize: '0.85rem',
          textOverflow: 'ellipsis',
        },
      },
      opposite: true,
      gridLineDashStyle: 'Solid',
      grid: {
        enabled: true,
        borderWidth: 2,
      },
      gridLineWidth: 45,
      gridLineColor: '#FBF8FF',
      gridZIndex: 0.4,
    },
    credits: false,
    tooltip: {
      enabled: true,
      outside: true,
      useHTML: true,
      shared: true,
      hideDelay: 1000,
      formatter: function (val) {
        const impactingHeading = () =>
          this.point?.goals?.length ? '<p>Impacting</p>' : '';

        const impactingGoals = () =>
          `${
            this.point?.goals?.length
              ? `<div class="tags-block">
              ${this.point?.goals?.slice(0, 2)?.map((item) => `<span class="chip">${item?.name}</span>`)}
              ${this.point?.goals?.length > 2 ? `<span class="chip">+${this.point?.goals?.length - 2}</span>` : ''}
            </div>`
              : ''
          }`;

        const delayReason = () =>
          `${
            this.point?.delay
              ? `<li>
              Delay reason
              <span>
                <a onclick="window.open('${this.point?.delayUrl}', '__blank')">View reason</a>
              </span>
            </li>`
              : ''
          }`;

        return `${`<div class="timeline-custom-tooltip" style=${this.point?.goals?.length > 0 ? '' : 'top:9rem'}>
                  ${impactingHeading()}
                  ${impactingGoals()}
                  <div class="buildcard-info">
                    <ul>
                      <li>
                        Space
                        <span>
                          <strong class="truncated-text">${this.point?.space_name?.trim()}</strong>
                        </span>
                      </li>
                      <li>
                        Kick-off <span>${this.point?.kickoff}</span>
                      </li>
                      <li>
                        Baseline <span>${this.point?.baseline}</span>
                      </li>
                      <li>
                        Forecasted
                        <span>
                          <strong>${this.point?.forcasted}</strong>
                        </span>
                      </li>
                      <li>
                        Complete
                        <span>
                          <strong>${this.point?.completion ?? 0}%</strong>
                        </span>
                      </li>
                      <li>
                        Health
                        <span class="chip ${this.point?.healthStatus}">${this.point?.healthStatus?.replace('_', ' ') ?? '-'}</span>
                      </li>
                      ${delayReason()}
                    </ul>
                  </div>
                </div>`}`;
      },
      positioner: function (a, b, point) {
        return { x: point.plotX + 400, y: point.plotY - 250 };
      },
      // borderWidth: 1,
      // backgroundColor: 'rgba(255,255,255,0.8)',
      style: {
        color: '#3C3E49',
        fontSize: '10px',
      },
    },
    plotOptions: {
      columnrange: {
        grouping: false,
        pointWidth: 22,
        dataLabels: {
          allowOverlap: false,
          inside: true,
          enabled: true,
          useHTML: true,
          verticalAlign: 'middle',
          align: 'center',
          color: '#FFFFFF',
          style: {
            fontWeight: 'normal',
            textOverflow: 'ellipsis',
            fontSize: '0.9rem',
            textOutline: 'none',
            strokeWidth: 0,
          },
          formatter: function (val) {
            let style = `text-align: center; text-overflow: ellipsis; overflow: hidden; display:inline-block; width: ${this.point?.plotLow - this.point?.plotHigh - 5}px; padding-left:10px; white-space: nowrap;`;
            return `<div style="${style}" title="${this.point?.name?.trim()}">${this.point?.name?.trim()}</div>`;
          },
        },
        shadow: {
          opacity: 0.15,
          color: '#EBEBEB',
        },
        states: {
          hover: {
            enabled: true,
          },
          inactive: {
            enabled: false,
          },
        },
      },
    },

    legend: {
      enabled: false,
    },
    series: data,
    accessibility: {
      enabled: false,
    },
  };
};

export const dashboardBudgetOption = (stats, sum, unallocated) => ({
  chart: {
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
    height: '300rem',
    spacing: [0, 0, 0, 0],
    marginBottom: -10,
    custom: {},
    events: {
      render() {
        const series = this.series[0];
        let customLabel = this.options.chart.custom.label;

        if (!customLabel) {
          customLabel = this.options.chart.custom.label = this.renderer
            .label(
              `<span style="font-size: 18px;line-height:24px;font-weight:500;color:#3C3E49;">${sum > 0 ? sum?.toFixed(2) : '0'}</span>` +
                '<br/><span style="font-size: 12px;line-height:16px;letter-spacing:0.068rem;color:#3C3E49;">USED CREDITS</span>'
            )
            .css({
              color: '#676B7E',
              textAnchor: 'middle',
            })
            .add();
        }

        const x = series.center[0] + this.plotLeft,
          y = series.center[1] + this.plotTop - customLabel.attr('height') / 2;

        customLabel.attr({
          x,
          y,
        });
        // Set font size based on chart diameter
        customLabel.css({
          fontSize: `${series.center[2] / 12}px`,
        });
      },
    },
  },
  legend: {
    alignColumns: false,
    itemStyle: {
      fontSize: '11px',
    },
  },
  title: {
    text: `${unallocated > 0 ? unallocated?.toFixed(2) : '0'} unallocated Credits`,
    align: 'center',
    verticalAlign: 'bottom',
    style: {
      fontWeight: 'normal',
      fontSize: '1.4rem',
    },
  },
  credits: false,
  colors: stats?.map((item) => item?.color),
  tooltip: {
    followPointer: true,
    outside: true,
    useHTML: true,
    formatter: function (tooltip) {
      return `
        <div class="chart-tooltip">
          <div class="body">
            <div class="content-row">
              <div class="count">${this.point.name}: ${this?.point?.y}</div>
            </div>
        </div>
      </div>`;
    },
  },
  plotOptions: {
    pie: {
      size: 190,
      dataLabels: {
        enabled: false,
      },
      showInLegend: false,
      borderWidth: 1,
      borderColor: '#FFFFFF',
      fillColor: '#ECEDF0',
      borderRadius: 0,
      shadow: {
        opacity: 0.15,
        color: '#EBEBEB',
      },
      states: {
        hover: {
          enabled: false,
        },
      },
    },
  },
  series: [
    {
      type: 'pie',
      innerSize: '75%',
      data: stats,
      states: {
        inactive: {
          enabled: false,
        },
      },
    },
  ],
  accessibility: {
    enabled: false,
  },
});

export const collaborationsTrendGraphOption = (stats) => {
  return {
    chart: {
      type: 'areaspline',
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'horizontal',
      x: 0,
      y: 0,
      itemStyle: {
        fontSize: '11px',
        fontWeight: '400 !important',
      },
    },
    xAxis: {
      lineColor: '#333333',
      lineWidth: 1,
      type: 'datetime',
      crosshair: {
        color: '#C0C3CE',
        width: '1px',
        interpolate: true,
        snap: true,
      },
      labels: {
        style: { fontSize: '12px' },
        formatter: function () {
          return stats?.labels[this.value];
        },
      },
    },
    yAxis: {
      min: 0,
      minRange: 2,
      allowDecimals: false,
      title: {
        text: '',
      },
      labels: {
        style: { fontSize: '12px' },
      },
    },
    tooltip: {
      headerFormat: '{point.name}<br>',
      shared: true,
      borderWidth: 1,
      borderColor: '#cccccc',
      backgroundColor: 'rgba(255,255,255,0.8)',
      shadow: {
        opacity: 0.05,
      },
      style: {
        fontSize: '11px',
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillOpacity: 0.3,
        stacking: 'normal',
      },
    },
    series: stats?.data,
    accessibility: {
      enabled: false,
    },
  };
};
