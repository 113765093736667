export default {
  addressIcon: require('./address-icon.svg').default,
  builderLogo: require('./builder-logo.svg').default,
  builderLogoB: require('./builder-logo-b.svg').default,
  builderEnterpriseLogo: require('./builder-enterprise-logo.svg').default,
  builderLogoBTrademark: require('./builder-logo-b-trademark.svg').default,
  businessGoals: require('./business-goals.svg').default,
  companySetup: require('./company-setup.svg').default,
  contactIcon: require('./contact-icon.svg').default,
  diamondPlanIcon: require('./diamond-plan-icon.svg').default,
  emptyBox: require('./empty-box.svg').default,
  emptyStateIllustration: require('./empty-state-illustration.svg').default,
  expandNnavIcon: require('./expand-nav-icon.svg').default,
  expandNnavIconGreen: require('./expand-nav-icon-green.svg').default,
  fileUpload: require('./file-upload.svg').default,
  goldPlanIcon: require('./gold-plan-icon.svg').default,
  goldPlanTransparentIcon: require('./gold-plan-transparent-icon.svg').default,
  googleLogo: require('./google-logo.svg').default,
  integrations: require('./integrations.svg').default,
  invitationEmail: require('./invitation-email.jpg'),
  inviteItAdmin: require('./invite-it-admin.svg').default,
  loginBg: require('./login-bg.png'),
  userImage4: require('./user.png'),
  makroLogo: require('./makro-logo.svg').default,
  manageCredits: require('./manage-credits.svg').default,
  memberAvatar: require('./member-avatar.jpg'),
  avatarAnim: require('./avatar-anim.svg').default,
  members: require('./members.svg').default,
  natashaLogo: require('./natasha-logo.svg').default,
  natashaLogoSm: require('./natasha-logo-sm.svg').default,
  natashaLogoShadow: require('./natasha-logo-shadow.svg').default,
  natashaIconBg: require('./natasha-icon-bg.svg').default,
  outlookLogo: require('./outlook-logo.svg').default,
  paymentPending: require('./payment-pending.svg').default,
  platinumPlanIcon: require('./platinum-plan-icon.svg').default,
  playIcon: require('./play-icon.svg').default,
  roundedTickGreen: require('./rounded-tick-green.svg').default,
  roundedTickPurple: require('./rounded-tick-purple.svg').default,
  signupSliderOne: require('./signup-slider01.png'),
  silverPlanIcon: require('./silver-plan-icon.svg').default,
  spacesFlag: require('./spaces-flag.svg').default,
  spacesFlagPurple: require('./spaces-flag-purple.svg').default,
  successIcon: require('./success-icon.svg').default,
  supportAvatar: require('./support-avatar.jpg'),
  userAvatar: require('./user-avatar.jpg'),
  userPhoto: require('./user-photo.jpg'),
  videoBanner: require('./video-banner.jpg'),
  visaLogo: require('./visa-logo.svg').default,
  checkboxUnchecked: require('./uncheck-icon.svg').default,
  checkboxChecked: require('./checked-icon.svg').default,
  checkboxUncheckedRound: require('./unchecked-icon-round.svg').default,
  checkboxCheckedRound: require('./checked-icon-round.svg').default,
  radioChecked: require('./radio-checked-icon.svg').default,
  radioUnchecked: require('./radio-unchecked-icon.svg').default,
  radioTickChecked: require('./radio-tick-checked-icon.svg').default,
  ikeaLogo: require('./ikea-logo.png'),
  editIcon: require('./edit-icon.svg').default,
  deleteIcon: require('./delete-icon.svg').default,
  deleteIconBlack: require('./delete-icon-black.svg').default,
  deleteIconBlue: require('./delete-icon-blue.svg').default,
  memberPhoto: require('./member-photo.png'),
  fullProfilePic: require('./full-profile-pic.jpg'),
  lock: require('./lock.svg').default,
  videoBannerStudio: require('./video-banner-studio.jpg'),
  userImage2: require('./user-image2.png'),
  userImage3: require('./user-image3.png'),
  uploadCloudIcon: require('./upload-cloud-icon.svg').default,
  fileCsv: require('./file-csv.svg').default,
  addPlusSquare: require('./icon-add-plus-square.svg').default,
  starRatingGold: require('./star-rating-gold.svg').default,
  interestThumb: require('./interest-thumb.png'),
  createWhiteboard: require('./whiteboard.svg').default,
  callTeam: require('./call-team.svg').default,
  openStudio: require('./open-studio.svg').default,
  logoA: require('./logo-a.svg').default,
  logoNineGag: require('./logo-nine-gag.svg').default,
  logoAsos: require('./logo-asos.svg').default,
  iconMultiUser: require('./icon-multi-user-mid.svg').default,
  iconMultiUserMin: require('./multiple-user-white-sm.svg').default,
  iconWhiteboard: require('./icon-whiteboard-mid.svg').default,
  iconFolder: require('./icon-folder-mid.svg').default,
  iconBuildcard: require('./icon-buildcard-mid.svg').default,
  iconFlag: require('./icon-flag-mid.svg').default,
  iconMeeting: require('./icon-calendar-mid.svg').default,
  iconEnvelope: require('./icon-envelope.svg').default,
  iconList: require('./icon-list.svg').default,
  iconGrid: require('./icon-grid.svg').default,
  iconGridLight: require('./icon-grid-light.svg').default,
  iconMultiUserXS: require('./multi-user-xs.svg').default,
  iconFolderXS: require('./folder-xs.svg').default,
  iconBuildcardXS: require('./buildcard-xs.svg').default,
  iconFlagXS: require('./flag-xs.svg').default,
  iconMeetingsXS: require('./calendar-xs.svg').default,
  iconWhiteboardXS: require('./whiteboard-xs.svg').default,
  iconNoNotifications: require('./no-notifications.svg').default,
  iconNextIdea: require('./light-bulb.svg').default,
  iconIdeaBoard: require('./light-bulb-white.svg').default,
  iconCheckCircleSuccess: require('./check-circle-succes.svg').default,
  iconCrossCircleError: require('./close-remove-cross-circle.svg').default,
  iconCrossCircle: require('./icon-cross-circle.svg').default,
  buAnim: require('./BU-anim.gif'),
  graphImg: require('./graph-img.jpg'),
  emptyTags: require('./empty-tags.svg').default,
  logoGoogleMeet: require('./logo-google-meet.svg').default,
  logoZoom: require('./logo-zoom.svg').default,
  healthChart: require('./health-chart.jpg'),
  todoReleaseChart: require('./todo-release-chart.png'),
  todoColorIcon: require('./todo-color-icon.svg').default,
  releaseColorIcon: require('./release-color-icon.svg').default,
  noActivity: require('./no-activity-icon.svg').default,
  pieChart: require('./pie-chart.jpg'),
  ticketsTrendChart: require('./tickets-trend-chart.jpg'),
  partyCone: require('./party-cone.svg').default,
  partyConeGreen: require('./party-cone-green.svg').default,
  bcColoredIcon: require('./bc-colored-icon.svg').default,
  appsColoredIcon: require('./apps-colored-icon.svg').default,
  projectsColoredIcon: require('./projects-colored-icon.svg').default,
  starsColoredIcon: require('./stars-colored-icon.svg').default,
  starsColoredExIcon: require('./stars-colored-exclamation-icon.svg').default,
  noteColoredIcon: require('./note-colored-icon.svg').default,
  goalColoredIcon: require('./goal-colored-icon.svg').default,
  calendarColoredIcon: require('./calendar-colored-icon.svg').default,
  spaceColoredIcon: require('./space-colored-icon.svg').default,
  spaceHorizontalColoredIcon: require('./space-horizontal-colored-icon.svg')
    .default,
  addWhiteboardIcon: require('./icon-add-whiteboard.svg').default,
  addWhiteboardIconMd: require('./icon-add-whiteboard-md.svg').default,
  addFileTextIconMd: require('./icon-file-text-add.svg').default,
  addMeetingIconMd: require('./icon-add-meeting-md.svg').default,
  noGraphIcon: require('./no-graph-icon.svg').default,
  noGraphBG: require('./no-graph-bg.svg').default,
  goalCountChartLightGrey: require('./circular-chart-light-grey.jpg'),
  goalCountChartColored: require('./circular-chart-colored.jpg'),
  cornerShapeNatashaIcon: require('./corner-shape-natasha-icon.svg').default,
  timelineGraph: require('./timeline-graph.jpg'),
  checkSuccessRounded: require('./check-success-rounded.svg').default,
  checkAccomplishedRounded: require('./check-accomplished-rounded.svg').default,
  participantsIcon: require('./icon-participants.svg').default,
  calendarIcon: require('./icon-calendar.svg').default,
  clockIcon: require('./icon-clock.svg').default,
  emptyTodoIcon: require('./empty state - todos.svg').default,
  copyIcon: require('./copy-text-icon.svg').default,
  goldPlanIcons: require('./goldplan-icon.svg').default,
  iconCheckCircleGreen: require('./check-green.svg').default,
  iconCheckCircleYellow: require('./check-yellow.svg').default,
  iconHeadphones: require('./headphones.svg').default,
  iconplanFrame: require('./plan-frame.svg').default,
  iconSent: require('./sent.svg').default,
  KPIPerformanceGraph: require('./kpi-graph.jpg'),
  iconWallet: require('./wallet.svg').default,
  iconBuilderCoin: require('./builder-coin.svg').default,
  linChart: require('./spline-chart.svg').default,
  Consumed: require('./consumed.svg').default,
  iconWarning: require('./warning.svg').default,
  iconQuestion: require('./question.svg').default,
  iconHistory: require('./icon-history.svg').default,
  creditsGrapgh: require('./credits-graph.png'),
  manuIcon: require('./menu-icon.svg').default,
  iconLock: require('./icon-lock-with-stars.svg').default,
  emptystateNosearch: require('./empty-state-nosearch-result.svg').default,
  iconArrow: require('./arrow-right.svg').default,
  iconMinus: require('./minus.svg').default,
  iconArrowActive: require('./arrow-right-blue.svg').default,
  imgNoDiscussion: require('./img-no-discussion.svg').default,
  imgDesktop: require('./img-desktop.svg').default,
  iconAndroid: require('./icon-android.svg').default,
  iconAndroidPurple: require('./icon-android-purple.svg').default,
  iconIOS: require('./icon-ios.svg').default,
  iconIOSPurple: require('./icon-ios-purple.svg').default,
  iconWatchOS: require('./icon-watchos.svg').default,
  iconWebsite: require('./icon-website.svg').default,
  iconGridB: require('./icon-grid-black.svg').default,
  iconNatasha: require('./icon-natasha.svg').default,
  logoBuilderEnterpriseOne: require('./Builder-Enterprise-one-logo.svg')
    .default,
  logoBuilderEnterpriseOnePlus: require('./enterprise-one-plus-logo.svg')
    .default,
  logoBuilderCloud: require('./Builder-cloud-logo.svg').default,
  iconProductRoadmap: require('./icon-product-roadmap.svg').default,
  iconProductRoadmapDisabled: require('./icon-product-roadmap-disabled.svg')
    .default,
  iconDesign: require('./icon-design.svg').default,
  iconDesignDisabled: require('./icon-design-disabled.svg').default,
  iconPrototype: require('./icon-prototype.svg').default,
  iconPrototypeDisabled: require('./icon-prototype-disabled.svg').default,
  iconBasicBuild: require('./icon-basic-build.svg').default,
  iconBasicBuildDisabled: require('./icon-basic-build-disabled.svg').default,
  iconFullBuild: require('./icon-fullbuild-purple.svg').default,
  iconFullBuildDisabled: require('./icon-fullbuild.svg').default,
  iconCustom: require('./icon-custom.svg').default,
  iconCustomDisabled: require('./icon-custom-disabled.svg').default,
  iconStopPoint: require('./icon-stop-point.svg').default,
  iconStopPointDisabled: require('./icon-stop-point-disabled.svg').default,
  iconNoMemberFound: require('./icon-no-member-found.svg').default,
  emptystateSortedBuildcard: require('./emptystate-sorted-buildcard.svg')
    .default,
  infoIcon: require('./info-icon.svg').default,
  warningIcon: require('./warning-icon.svg').default,
  builderCrypto: require('./builder-crypto-currencies.svg').default,
  mobilePhoneLogo: require('./mobile-phone-device-logo.svg').default,
  mapsNavigation: require('./maps-navigation.svg').default,
  graphSpendAnalysis: require('./graph-spend-analysis.png'),
  timelineGraph2: require('./timeline-graph1.png'),
  budgetGraph: require('./graph-budget.png'),
  changeManagementGraphSVG: require('./change-management-graph.svg').default,
  collaborationTrendSVG: require('./collaboration-graph.svg').default,
  graphReleases: require('./graph-releases.png'),
  IconAttach: require('./attach-icon.svg').default,
  exportIcon: require('./export.svg').default,
  paymentsIcon: require('./payments-icon.svg').default,
  iconNoDataYellowThumb: require('./icon-no-data-yellowthumb.svg').default,
  iconNoContac: require('./no-contact-icon.svg').default,
  iconNoBilling: require('./loc-icon.svg').default,
  iconNoTax: require('./tax-icon.svg').default,
  checkboxBlue: require('./checkbox-icon.svg').default,
  uncheckboxGray: require('./uncheckgray-icon.svg').default,
  iconBell: require('./icon-bell.svg').default,
};
