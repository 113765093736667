import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DropdownIcon from '../../components/utils/DropdownIcon';
import instance from '../interceptor';
import { setSuccessMsg } from '../store/slices/successSlice';
import { companyState } from '../store/slices/userSlice';
import { COMPANY, SPACES, goalStatusList } from '../util/constant';
import {
  formatDate,
  getColorCodeAndClassForGoals,
  getFullname,
  globalError,
} from '../util/util';
import LoaderComp from './LoaderComp';

const GoalDetailCard = ({
  goalDetail,
  getGoalDetail,
  setOpenEditDialogue = null,
  update = null,
  title = null,
}) => {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const params = useParams();

  const [statusLoading, setStatusLoading] = useState(false);

  const changeStatus = async (e) => {
    try {
      const val = e?.target?.value;
      setStatusLoading(true);
      let url;
      let payload;
      if (update == 'kpi') {
        url = `${COMPANY}/${compSel?.id}/company_goals/${params?.goal_id}/kpis/${goalDetail?.id}`;
        payload = {
          kpi: {
            name: goalDetail?.name,
            description: goalDetail?.description,
            due_date: moment(goalDetail?.due_date).format('YYYY-MM-DD'),
            space_ids: goalDetail?.spaces?.map((space) => space?.id),
            status: val,
            owner_id: goalDetail?.owner?.id,
          },
        };
      } else if (update == 'space_goal') {
        url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${goalDetail?.id}`;
        payload = {
          name: goalDetail?.name,
          description: goalDetail?.description,
          status: val,
          due_date: goalDetail?.due_date,
          company_goal_ids: goalDetail?.company_goal_ids,
          archive: goalDetail?.archive,
          owner_id: goalDetail?.owner?.id,
        };
      } else {
        url = `${COMPANY}/${compSel?.id}/company_goals/${goalDetail?.id}`;
        payload = {
          company_goal: {
            name: goalDetail?.name,
            description: goalDetail?.description,
            private: goalDetail?.private,
            archive: goalDetail?.archive,
            status: val,
            due_date: goalDetail?.due_date,
            owner_id: goalDetail?.owner?.id,
          },
        };
      }
      await instance.patch(url, payload);
      getGoalDetail();
      dispatch(setSuccessMsg('Status updated successfully.'));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setStatusLoading(false);
    }
  };

  //========================================================
  return (
    <div className="status-content-wrap" aria-label="goal-detail-card">
      {title?.heading && (
        <div className="description-wrap">
          <h4 className="title">{title.heading}</h4>
          <p className="description-text">{title.description}</p>
        </div>
      )}
      <div className="inner-container">
        <div className="col-wrap">
          <div className="col">
            <label>Status</label>
            <div className="content-wrap dropdown-contaier">
              {/* { onTrack: '#69F0AE' },{ progressing: '#FFD740' },{ offTrack: '#FF8A80' },{ accomplished: '#00C853' } */}
              <em
                className="dot md"
                style={{
                  backgroundColor: getColorCodeAndClassForGoals(
                    goalDetail?.status,
                    goalDetail?.archive
                  )?.color,
                }}
              ></em>
              {!statusLoading && (
                <Select
                  IconComponent={DropdownIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                    classes: { list: 'p-0' },
                  }}
                  inputProps={{ 'data-testid': 'card-select' }}
                  className="select-status borderless-dropdown select-icon"
                  value={goalDetail?.status || ''}
                  defaultValue="on_track"
                  label="Status"
                  onChange={changeStatus}
                >
                  {goalStatusList.map((status) => (
                    <MenuItem
                      className="status-menu-item"
                      key={status?.id}
                      value={status?.value}
                    >
                      <em
                        className="dot sm"
                        style={{
                          backgroundColor: status?.color,
                          border: status?.border,
                        }}
                      ></em>{' '}
                      <span className="status-dropdown-label">
                        {status?.text}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              )}
              {statusLoading && <LoaderComp />}
            </div>
          </div>
          <div className="col">
            <label>Kicked off</label>
            <div className="content-wrap">
              <div className="context-label">
                {formatDate(
                  goalDetail?.kickoff_date ?? goalDetail?.created_at,
                  false,
                  false,
                  'DD MMM YYYY'
                )}
              </div>
            </div>
          </div>
          <div className="col">
            <label>Target date</label>
            <div className="content-wrap">
              <div className="context-label">
                {formatDate(goalDetail?.due_date, false, false, 'DD MMM YYYY')}
              </div>
            </div>
          </div>

          <div className="col ">
            <label>Credits spent</label>
            <div className="content-wrap">
              <div className="context-label">
                {goalDetail?.total_consumed_credits}
              </div>
            </div>
          </div>
          <div className="col no-border">
            <label>Owner</label>
            <div className="owner-name">
              {goalDetail?.owner ? (
                (getFullname(
                  goalDetail?.owner?.first_name,
                  goalDetail?.owner?.last_name
                ) ?? goalDetail?.owner?.email)
              ) : (
                <span
                  className="link-text"
                  onClick={() => setOpenEditDialogue(true)}
                  style={{ cursor: 'pointer' }}
                >
                  Assign owner
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-wrap">&nbsp;</div>
      </div>
    </div>
  );
};

export default GoalDetailCard;
