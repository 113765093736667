import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Dialog, DialogContent, Slider } from '@mui/material';
import { useState } from 'react';

function SpaceMeetingAttachmentDialog({
  openMeeting,
  handleModalClose,
  downloadAttachments,
  setOpenMeeting,
}) {
  const [scale, setScale] = useState(1);
  let re = /(?:\.([^.]+))?$/;

  return (
    <Dialog
      className="attachment-dialog"
      open={Boolean(openMeeting)}
      onClose={handleModalClose}
    >
      <DialogContent className="no-btns">
        <div className="modal-body">
          <div className="connect-with-expert view-attachment-content-wrap">
            <div className="modal-title">
              <h2>{openMeeting?.file_identifier}</h2>
              <div className="right">
                <a
                  className="download-link-btn xs-btn btn-white"
                  style={{ color: 'inherit' }}
                  onClick={() => downloadAttachments(openMeeting)}
                >
                  Download
                </a>
                <span
                  className="modal-close be-close"
                  onClick={() => setOpenMeeting(null)}
                ></span>
              </div>
            </div>
            {!['jpg', 'jpeg', 'png'].includes(
              re.exec(openMeeting?.file_identifier)[1]
            ) && (
              <div className="doc-viewer-wrap">
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={[
                    {
                      uri: openMeeting?.url,
                      // fileType: re.exec(openMeeting?.file_identifier)[1],
                    },
                  ]}
                  config={{
                    header: { disableFileName: true, disableHeader: true },
                  }}
                  style={{
                    objectFit: 'contain',
                    transform: `scale(${scale})`,
                    transition: 'all .5s',
                    minHeight: '55rem',
                  }}
                />
              </div>
            )}
            {['jpg', 'jpeg', 'png'].includes(
              re.exec(openMeeting?.file_identifier)[1]
            ) && (
              <>
                <div className="meeting-attachment-image-wrap">
                  <img
                    style={{
                      zoom: scale,
                      transition: 'all .5s',
                    }}
                    src={openMeeting?.url}
                    crossOrigin="anonymous"
                    alt=""
                  />
                </div>

                <div
                  className="resize-slider"
                  style={{ textAlign: 'center', marginTop: '2rem' }}
                >
                  <Slider
                    defaultValue={1}
                    min={1}
                    max={3}
                    step={0.1}
                    value={scale}
                    onChange={(e) => setScale(Number(e.target?.['value']))}
                    style={{ width: '500px' }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SpaceMeetingAttachmentDialog;
