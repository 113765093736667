import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import images from '../../../assets/images';
import DropdownIcon from '../../../components/utils/DropdownIcon';
import instance from '../../../shared/interceptor';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import {
  getFullname,
  getInitials,
  globalError,
} from '../../../shared/util/util';
import {
  creditAllocationGraphData,
  creditConsumptionGraph,
  creditsGraph,
} from '../../dashboard/dashboardGraphData';

function CreditOverview({
  spaceCredits,
  emptyStateOverview,
  plan,
  graphData,
  searchSpaceCredits,
  getCreditPlanSub,
  getSpaceCredit,
  setValue,
  creditPlanLoading,
  spaceCreditLoading,
}) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);

  const [sortAsc, setSortAsc] = useState(true);
  const [archive, setArchive] = useState(true);
  const [allocationGraphData, setAllocationGraphData] = useState(null);
  const [creditConsumptionGraphData, setCreditConsumptionGraphData] =
    useState(null);
  const [spaceOverviewCredits, setSpaceOverviewCredits] = useState(
    spaceCredits?.filter((item) => item?.allocated_credits)
  );
  const [allocationGraphLoading, setAllocationGraphLoading] = useState(true);
  const [creditConsumptionLoading, setCreditConsumptionLoading] =
    useState(true);

  const handleSortChange = (event) => {
    const newValue = event.target.value === 'asc';
    setSortAsc(newValue);
    let arr = archive ? spaceCredits : searchSpaceCredits;
    if (newValue) {
      setSpaceOverviewCredits(
        arr?.sort((a, b) => a.allocated_credits - b.allocated_credits)
      );
    } else {
      setSpaceOverviewCredits(
        arr?.sort((a, b) => b.allocated_credits - a.allocated_credits)
      );
    }
  };

  useEffect(() => {
    getCreditPlanSub();
    getSpaceCredit();
    getCreditConsumptionGraphData();
  }, []);

  useEffect(() => {
    getDistributionGraphData();
  }, []);

  useEffect(() => {
    if (plan?.allocated_space_credits_data?.length) {
      setSpaceOverviewCredits(filteringArrBasedOnAnother(spaceCredits));
    } else {
      setSpaceOverviewCredits(
        spaceCredits?.sort(
          (a, b) => a?.allocated_credits - b?.allocated_credits
        )
      );
    }
  }, [spaceCredits]);

  useEffect(() => {
    setAllocationGraphData((prev) => ({
      ...prev,
      allocated_credits_percentage: plan?.allocated_credits_percentage,
    }));
  }, [plan?.allocated_credits_percentage]);

  const toggleArchive = () => {
    setArchive(!archive);
    if (archive) {
      setSpaceOverviewCredits(sortingInArchive(searchSpaceCredits));
    } else {
      setSpaceOverviewCredits(sortingInArchive(spaceCredits));
    }
  };

  const getDistributionGraphData = async () => {
    try {
      setAllocationGraphLoading(true);
      const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/allocation_distribution`;
      let res = await instance.get(url);
      setAllocationGraphData(res?.['plan_credit_subscription']);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setAllocationGraphLoading(false);
    }
  };

  const getCreditConsumptionGraphData = async () => {
    try {
      setCreditConsumptionLoading(true);
      const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/monthly_credit_consumption_data`;
      let res = await instance.get(url);
      setCreditConsumptionGraphData(res);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setCreditConsumptionLoading(false);
    }
  };

  const sortingInArchive = (arr) => {
    return arr.sort((a, b) => {
      if (sortAsc == true) {
        return a.allocated_credits - b.allocated_credits;
      } else {
        return b.allocated_credits - a.allocated_credits;
      }
    });
  };

  const filteringArrBasedOnAnother = (arr) => {
    return arr
      ?.filter((item) =>
        plan?.allocated_space_credits_data?.find(
          (elem) => elem?.space_id == item?.space_id
        )
      )
      ?.sort((a, b) => a?.allocated_credits - b?.allocated_credits);
  };

  //===================================
  return (
    <>
      <ul className="total-credits-block">
        <li>
          {creditPlanLoading && (
            <LoaderComp border={true} style={{ height: '13vh' }} />
          )}
          {!creditPlanLoading && (
            <div className="credits-box be-radius">
              <div className="credits-left">
                <p>
                  Total credits <em className="icon be-info"></em>
                </p>
                <h2 className="total">{plan?.total_credits}</h2>
              </div>
              <div className="credits-right">
                <em className="thumb">
                  <img src={plan?.plan?.image_url} alt={plan?.plan?.name} />
                </em>
              </div>
            </div>
          )}
        </li>

        <li>
          {creditPlanLoading && (
            <LoaderComp border={true} style={{ height: '13vh' }} />
          )}
          {!creditPlanLoading && (
            <div className="credits-box be-radius">
              <div className="credits-left">
                <p>
                  Credits allocated <em className="icon be-info"></em>
                </p>
                <h2 className="total">
                  {plan?.allocated_credits}
                  <sub>{plan?.allocated_credits_percentage}%</sub>
                </h2>
              </div>
              <div className="credits-right">
                <em className="thumb">
                  <img
                    src={(images as any).iconBuilderCoin}
                    alt="Builder Coin"
                  />
                </em>
              </div>
            </div>
          )}
        </li>

        <li>
          {creditPlanLoading && (
            <LoaderComp border={true} style={{ height: '13vh' }} />
          )}
          {!creditPlanLoading && (
            <div className="credits-box be-radius">
              <div className="credits-left">
                <p>
                  Credit balance <em className="icon be-info"></em>
                </p>
                <h2 className="total">
                  {plan?.credit_balance}
                  <sub>{plan?.credit_balance_percentage}%</sub>
                </h2>
              </div>
              <div className="credits-right">
                <em className="thumb">
                  <img src={images.iconWallet} alt="Wallet" />
                </em>
              </div>
            </div>
          )}
        </li>
      </ul>

      <div className="credit-allocation-row">
        <div className="credit-allocation-col">
          {allocationGraphLoading && (
            <LoaderComp border={true} style={{ height: '35vh' }} />
          )}
          {!allocationGraphLoading &&
            allocationGraphData?.space_credits_count > 0 && (
              <div className="card">
                <div
                  className="card-title"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h4>Credit allocation distribution </h4>
                  <h6>
                    {allocationGraphData?.allocated_credits_percentage ?? 0} %
                  </h6>
                </div>
                <span>{allocationGraphData?.space_credits_count} Spaces</span>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={creditAllocationGraphData(allocationGraphData)}
                />
              </div>
            )}
        </div>
        <div className="credit-allocation-col">
          {creditConsumptionLoading && (
            <LoaderComp border={true} style={{ height: '35vh' }} />
          )}
          {!creditConsumptionLoading &&
            Object.keys(creditConsumptionGraphData ?? {})?.length > 0 && (
              <div className="card">
                <h4>Allocated credits usage</h4>
                <span>
                  This graph shows the accumulated usage of credits in the
                  current cycle.{' '}
                </span>
                <div className="credit-allocated-graph-wrap overflow-x-auto">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={creditConsumptionGraph(creditConsumptionGraphData)}
                  />
                </div>
              </div>
            )}
        </div>
      </div>

      {emptyStateOverview && (
        <div className="no-data-wrapper no-allocations">
          <em className="thumb">
            <img src={images.emptyStateIllustration} alt="Spaces Flag" />
          </em>
          <h4 aria-label="no-space">No allocations yet</h4>
          <p>You have not allocated credits to your spaces yet.</p>
          <Button
            variant="contained"
            className="xs-btn"
            onClick={() => setValue('2')}
          >
            Allocate Credits
          </Button>
        </div>
      )}

      {spaceCreditLoading && (
        <LoaderComp border={true} style={{ height: '20vh' }} />
      )}
      {!spaceCreditLoading && (
        <div className="current-allocations card">
          <div className="d-flex justify-content-between card-title">
            <h4>Current allocations</h4>
            <h4>
              <span className="total-credits">Total Credits:</span>
              {plan?.total_credits}
            </h4>
          </div>
          <div className="">
            <em className="thumb">
              <HighchartsReact
                highcharts={Highcharts}
                options={creditsGraph(graphData, {
                  height: '60px',
                  fontStyle: 'normal',
                  fontSize: '12px',
                  pointWidth: 12,
                })}
              />
            </em>
          </div>
          <div className="credit-spaces">
            <h5>Spaces</h5>
            <div className="right-filter">
              <Tooltip
                placement="top"
                arrow
                title={
                  !archive ? 'Hide archived spaces' : 'Show archived spaces'
                }
                classes={{ popper: 'info-tooltip' }}
              >
                <Button
                  onClick={toggleArchive}
                  style={!archive ? { background: '#f3ebff' } : {}}
                  data-testid="wb-btn"
                  variant="outlined"
                  className="xs-btn is-icon-btn be-archive"
                ></Button>
              </Tooltip>
              <div className="form-group">
                <Select
                  IconComponent={DropdownIcon}
                  className="select-icon borderless-dropdown sm-height sm-right no-label"
                  value={sortAsc ? 'asc' : 'desc'}
                  onChange={handleSortChange}
                >
                  <MenuItem className="sorting-menu-item" value="asc">
                    Low to High
                  </MenuItem>
                  <MenuItem className="sorting-menu-item" value="desc">
                    High to Low
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div className="credits-list">
            {spaceOverviewCredits
              ?.filter((item) => item?.allocated_credits)
              ?.map((credits) => (
                <div className="requested-block" key={credits?.id}>
                  <div className="user-link">
                    <div className="user-icon ">
                      <div
                        className="users-box"
                        style={{ backgroundColor: credits?.space?.color }}
                      >
                        <span className="icon be-multiple-users"></span>
                      </div>
                      <h5 className="truncated-text">{credits?.space?.name}</h5>{' '}
                      {credits?.space?.archive && (
                        <span className="be-archive archive-icon"></span>
                      )}
                      {credits?.pending_space_credit_request && (
                        <div className="warning">
                          <Tooltip
                            placement="top"
                            arrow
                            title={`${credits?.pending_space_credit_request?.credit_requested} credits requested by Space Manager`}
                            classes={{ popper: 'info-tooltip' }}
                          >
                            <img src={images.iconWarning} alt="" />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    {(getFullname(
                      credits?.space?.owner?.first_name,
                      credits?.space?.owner?.last_name
                    ) ||
                      credits?.space?.owner?.profile_picture
                        ?.profile_picture_small) && (
                      <div className="user-name">
                        <em className="initials ">
                          {credits?.space?.owner?.profile_picture
                            ?.profile_picture_small ? (
                            <img
                              src={
                                credits?.space?.owner?.profile_picture
                                  ?.profile_picture_small
                              }
                              alt=""
                            />
                          ) : (
                            getInitials(
                              getFullname(
                                credits?.space?.owner?.first_name,
                                credits?.space?.owner?.last_name
                              )
                            )
                          )}
                        </em>{' '}
                        {getFullname(
                          credits?.space?.owner?.first_name,
                          credits?.space?.owner?.last_name
                        )}
                      </div>
                    )}
                  </div>
                  <div className="progress-bar">
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress
                        variant="determinate"
                        value={
                          credits?.allocated_credits
                            ? (credits?.consumed_credits /
                                credits?.allocated_credits) *
                              100
                            : 0
                        }
                      />
                    </Box>
                    <span className="progressbar-num">
                      {Math.round(credits?.consumed_credits)}/
                      {Math.round(credits?.allocated_credits)}
                    </span>
                  </div>
                </div>
              ))}
            {/* <div className='requested-block'>
                    <div className="user-link">
                        <div className="user-icon">
                            <div className='users-box grey'>
                            <span className="icon be-multiple-users"></span>
                            </div>
                            <h5>Equipments</h5> <span className='be-archive archive-icon'></span>
                        </div>
                        <div className="user-name">
                            <em className="">
                            <img src={(images as any).userImage4} alt="" />
                            </em> Annie Cho
                        </div>
                    </div>
                    <div className="progress-bar">
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress variant="determinate" value={30} />
                        </Box>
                        <span className='progressbar-num'>80/120</span>
                    </div>
                </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default CreditOverview;
