import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { companyState } from '../../../shared/store/slices/userSlice';
import { useParams } from 'react-router-dom';
import { modifyBuildCardAttributes } from '../../../shared/services/common.service';
import { setLoader } from '../../../shared/util/util';

function ArchiveBuildcardDialog({
  card,
  openArchiveBc,
  handleArchiveClose,
  updateBuildCardAttributes,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);

  const handleArchiveClick = async (e) => {
    e?.stopPropagation();
    try {
      setLoader(dispatch, true);
      const archive = !card?.archive;
      await modifyBuildCardAttributes(
        compSel?.id,
        params?.id,
        card?.enterprise_id ?? card?.id,
        { archive },
        () =>
          updateBuildCardAttributes(card?.id, {
            archive,
          }),
        dispatch,
        `
        ${card?.name} has been ${card?.archive ? 'un' : ''}archived.`
      );
      handleArchiveClose();
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  return (
    <Dialog
      className="archive-buildcard-modal"
      open={openArchiveBc}
      onClose={handleArchiveClose}
    >
      <DialogContent aria-label="archive-modal">
        {/* <span className="modal-close be-close" onClick={archiveSpaceDialogClose}></span> */}
        <div className="modal-body">
          <div>
            <h2 style={{ marginBottom: '0.8rem' }}>
              {card?.archive ? 'Unarchive' : 'Archive'} Buildcard
            </h2>
            <p>
              {card?.archive
                ? 'This Buildcard will be visible in your main view.'
                : 'This Buildcard will be hidden from your main view. You can still access archived items later through the filters menu and unarchive.'}
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            data-testid="close-revoke"
            variant="contained"
            className="sm-btn grey-btn"
            onClick={handleArchiveClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="sm-btn green-btn"
            aria-label="archive-confirm"
            onClick={handleArchiveClick}
          >
            {`Yes, ${card?.archive ? 'un' : ''}archive`}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ArchiveBuildcardDialog;
