import Button from '@mui/material/Button';
import { startTransition, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import {
  createExternalAppSession,
  getCurrentSpaceDetail,
  sendSlackNotification,
} from '../../../shared/services/common.service';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import {
  companyState,
  permissionState,
} from '../../../shared/store/slices/userSlice';
import {
  ON_BOARDING_WAITING_PAYMENT,
  SPACES,
  SPACE_LIST,
} from '../../../shared/util/constant';
import {
  globalError,
  isAllowedTo,
  setLoader,
  transNavigation,
} from '../../../shared/util/util';
import '../_buildingSpaces.scss';
import RightPageHeader from '../../../shared/shared-comp/RightPageHeader';

const PlaygroundWhiteboard = () => {
  const [frameUrl, setFrameUrl] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentSpaceSel = useSelector(currentSpaceState);
  const compSel = useSelector(companyState);
  const permissions = useSelector(permissionState);

  const [iframeLoading, setIframeLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [wb, setWb] = useState(null);

  useEffect(() => {
    blockInspect(true);

    return () => {
      blockInspect(false);
    };
  }, []);

  useEffect(() => {
    if (!currentSpaceSel?.id) {
      getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
    }
    if (!params?.whiteboard_id) {
      navigate(-1);
    } else {
      getWbUrl();
    }
  }, [params?.id, params?.whiteboard_id]);

  const getWbUrl = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/whiteboards/${params?.whiteboard_id}`;
      let wbRes = await instance.get(url);
      setWb(wbRes?.['whiteboard']);
      setStartTime(performance.now());
      setFrameUrl(wbRes?.['whiteboard']?.['url']);
      createExternalAppSession(params, compSel, 'whiteboard', dispatch);
      setIframeLoading(true);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const blockInspect = (def) => {
    document.oncontextmenu = document.body.oncontextmenu = function () {
      return def ? false : true;
    };
    document.onkeydown = (e) => {
      if ((e.key as any) == 123) {
        return def ? e.preventDefault() : true;
      }
      if (e.ctrlKey && e.shiftKey && e.key == 'I') {
        return def ? e.preventDefault() : true;
      }
      if (e.ctrlKey && e.shiftKey && e.key == 'C') {
        return def ? e.preventDefault() : true;
      }
      if (e.ctrlKey && e.shiftKey && e.key == 'J') {
        return def ? e.preventDefault() : true;
      }
      if (e.ctrlKey && e.key == 'U') {
        return def ? e.preventDefault() : true;
      }
    };
  };

  const handleIframLoad = () => {
    setIframeLoading(false);
    let endTime = performance.now();
    let timeTaken = endTime - startTime;
    if (
      process.env.NODE_ENV !== 'development' &&
      startTime &&
      timeTaken > 5000
    ) {
      sendSlackNotification(
        'Whiteboard',
        timeTaken,
        'whiteboard',
        `${params?.id}/whiteboard-playground/${params?.whiteboard_id}`,
        dispatch
      );
    }
  };

  //==================================================================
  return (
    <div className="whiteboard-wrapper" aria-label="playground-whiteboard">
      <div className="iframe-page-head">
        <div className="main-page-header">
          <div className="left-page-header">
            <div className="page-breacrumb">
              <Button
                aria-label="ideaboard-btn"
                variant="contained"
                className="sm-btn is-icon-btn purple-btn btn-back"
                onClick={() => {
                  setFrameUrl('');
                  navigate(`/company/spaces/${params?.id}/ideaboard`);
                }}
              >
                <em className="btn-icon be-left-arrow"></em>
              </Button>
              <div className="breadcrumb-wrapper">
                <ul className="breadcrumb-links">
                  <li>
                    <a
                      onClick={() =>
                        transNavigation(
                          startTransition,
                          navigate,
                          ON_BOARDING_WAITING_PAYMENT
                        )
                      }
                    >
                      Building
                    </a>
                  </li>
                  <li>
                    {isAllowedTo('update', 'space', permissions) ? (
                      <Link to={SPACE_LIST}>Spaces</Link>
                    ) : (
                      'Spaces'
                    )}
                  </li>
                  <li>
                    <Link to={`/company/spaces/${params?.id}/overview`}>
                      {currentSpaceSel?.name}
                    </Link>
                  </li>
                  <li
                    aria-label="link-ideaboard"
                    className="cr-pointer"
                    onClick={() => {
                      navigate(`/company/spaces/${params?.id}/ideaboard`);
                      setFrameUrl('');
                      setWb(null);
                    }}
                  >
                    Ideaboard
                  </li>
                  <li>{wb?.name}</li>
                </ul>
              </div>
            </div>
            {/* To be enable later
            <div className='dropdown-wrap'>
              <Button data-testid="menu-item" variant="contained" className='xxs-btn purple-btn' onClick={handleClick}>Invite <em className="be-chevron-down"></em></Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className='custom-dropmenu action-menu-dropdown invite'
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem className='menu-item'>Option 1</MenuItem>
              </Menu>
            </div> */}
          </div>
          <RightPageHeader />
        </div>

        {iframeLoading && (
          <div className="B-loading-wrapper">
            <img
              src={(images as any).buAnim}
              alt="B"
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                height: '80px',
                width: '80px',
                transform: 'translate(-50%, -50%)',
                zIndex: '9999',
              }}
            />
          </div>
        )}

        {frameUrl && (
          <iframe
            aria-label="iframe-label"
            title="whiteboard application"
            onLoad={handleIframLoad}
            src={frameUrl}
            className="wb-integration-iframe"
          />
        )}
      </div>
    </div>
  );
};

export default PlaygroundWhiteboard;
