import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { companyState } from '../../shared/store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { renameBuildCard } from '../../shared/services/common.service';

function EditBuildcardNameDialog({
  card,
  openRenameBc,
  handleClose,
  updateBuildCardAttributes,
}) {
  const [cardName, setCardName] = useState(card?.alias_name ?? card?.name);
  const [error, setError] = useState(null);
  const compSel = useSelector(companyState);
  const dispatch = useDispatch();

  const onDialogClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleClose(e);
  };

  const handleRenameClick = async (e) => {
    e?.stopPropagation();
    let trimmedCardName = cardName?.trim();
    if (trimmedCardName) {
      if (trimmedCardName === (card?.alias_name ?? card?.name)) {
        setError('Please add a different name');
      } else if (trimmedCardName?.length > 30) {
        setError(
          'Buildcard name should be less than or equal to 30 characters'
        );
      } else {
        setError(null);
        try {
          await renameBuildCard(
            compSel?.id,
            card?.space_id,
            card?.enterprise_id,
            trimmedCardName,
            () =>
              updateBuildCardAttributes(card?.id, {
                name: trimmedCardName,
                alias_name: trimmedCardName,
              }),
            dispatch
          );
          handleClose();
        } catch (error) {
          setError('Failed to rename Buildcard');
        }
      }
    } else {
      setError("Buildcard name can't be blank");
    }
  };

  return (
    <Dialog open={openRenameBc} onClose={onDialogClose}>
      <DialogContent>
        <div
          aria-label="edit-profile"
          className="modal-body edit-buildcard-dialog"
        >
          <div className="modal-title">
            <h2>Rename Buildcard</h2>
          </div>
          <div className="modal-form">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl variant="filled">
                    <label>Add a name that best describes your project.</label>
                    <TextField
                      className={error ? 'has-error' : ''}
                      id="filled-error-helper-text"
                      value={cardName}
                      variant="filled"
                      onChange={(e) => setCardName(e?.target?.value)}
                    />
                  </FormControl>
                  {error && (
                    <span aria-label="error" className="form-error">
                      {error}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            variant="contained"
            aria-label="close-edit-modal"
            className="grey-btn sm-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            data-testid="save-btn"
            className="sm-btn green-btn"
            variant="contained"
            onClick={handleRenameClick}
          >
            Save
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default EditBuildcardNameDialog;
