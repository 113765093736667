import '@cyntler/react-doc-viewer/dist/index.css';
import { Menu, MenuItem } from '@mui/material';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import images from '../../assets/images';
import SpaceMeetingAttachmentDialog from '../../components/dialog/space-meeting-dialog/SpaceMeetingAttachmentDialog';
import CopyButton from '../../components/utils/CopyButton';
import OverflowTooltip from '../../shared/shared-comp/OverflowTooltip';
import { bmeetUrl } from '../../shared/store/slices/userSlice';
import { getInitials, getRandom } from '../../shared/util/util';
import './space-meetings.scss';

export default function MeetingCard({ meet }) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [openMeeting, setOpenMeeting] = useState(null);

  let re = /(?:\.([^.]+))?$/;

  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl({ [id]: event.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl2);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const openMeetingUrl = () => {
    if (meet?.meeting_provider == 'meet') {
      dispatch(bmeetUrl(meet));
      navigate(`/company/spaces/${params?.id}/meeting-playground/${meet?.id}`);
    } else {
      window.open(meet?.url, '__blank');
    }
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    setOpenMeeting(null);
  };

  const userDisplayIcon = (user) =>
    user?.profile_picture?.profile_picture_small ? (
      <img
        src={user?.profile_picture?.profile_picture_small}
        alt=""
        className="initials"
      />
    ) : (
      <em className="initials">
        {getInitials(user?.name) || getInitials(user?.email)}
      </em>
    );

  const acceptedExt = (fileName) => {
    let acceptedFileType = [
      'bmp',
      'csv',
      'odt',
      'doc',
      'docx',
      'gif',
      'htm',
      'html',
      'jpg',
      'jpeg',
      'pdf',
      'png',
      'ppt',
      'pptx',
      'tiff',
      'txt',
      'xls',
      'xlsx',
      'mp4',
      'webp',
    ];

    let fileExt = fileName.split('.').pop();
    return acceptedFileType.includes(fileExt);
  };

  const downloadAttachments = (meeting) => {
    saveAs(meeting?.url, meeting?.file_identifier);
  };

  //===========================================================
  return (
    <div>
      <div className="meeting-card-section">
        {meet?.agenda && (
          <div className="meeting-card">
            <h3>Agenda</h3>
            <div className="agenda-block">
              <div dangerouslySetInnerHTML={{ __html: meet?.agenda }}></div>
            </div>
          </div>
        )}
        <div className="meeting-card">
          <h3>
            Participants{' '}
            {meet?.users?.length > 0 && (
              <span className="count">
                {meet?.users?.length >= 0 &&
                  meet?.users?.length + (meet?.host?.name ? 1 : 0)}
              </span>
            )}
          </h3>
          <ul className="participants-block">
            {meet?.host?.name && (
              <li>
                {userDisplayIcon(meet?.host)}
                <span>{meet?.host?.name || meet?.host?.email} (Host)</span>
              </li>
            )}
            {meet?.users
              // ?.filter((user) => user?.id !== meet?.host?.id)
              ?.slice(0, 4)
              ?.map((user) => (
                <li key={user?.id ?? getRandom()}>
                  {userDisplayIcon(user)}
                  <OverflowTooltip
                    text={user?.name || user?.email}
                    tooltip={user?.name || user?.email}
                  />
                </li>
              ))}
            {meet?.users?.length > 4 && (
              <li
                style={{ cursor: 'pointer' }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                onClick={handleClick2}
              >
                +{meet?.users?.length - 4}
              </li>
            )}
            <Menu
              style={{ marginLeft: '-8.5%' }}
              className="ideaboard-tooltip table-wrapper"
              id="basic-menu"
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {meet?.users?.slice(4, meet?.users?.length)?.map((user) => (
                <MenuItem key={user?.id ?? getRandom()} onClick={handleClose}>
                  {userDisplayIcon(user)}{' '}
                  <OverflowTooltip
                    text={user?.name || user?.email}
                    tooltip={user?.name || user?.email}
                  />
                </MenuItem>
              ))}
            </Menu>
          </ul>
        </div>
        <div className="meeting-card">
          <div className="meeting-links">
            <h3>Meeting URL</h3>
            <div className="link-container">
              <a onClick={openMeetingUrl}>{meet?.url} </a>
              <CopyButton
                copyText={meet?.url}
                btnStyle="transparent-btn xs-btn copy-btn"
              />
            </div>
          </div>
        </div>

        {meet?.attachments?.length > 0 && (
          <div className="meeting-card">
            <h3>Attachments</h3>
            {meet?.attachments?.map((att) => (
              <div className="attachement-item" key={att?.id}>
                <div className="attach-icon">
                  <img src={images.IconAttach} alt="Buildcard" />
                </div>
                <div className="attachment-middle">
                  {acceptedExt(att?.file_identifier) && (
                    <span
                      className="file-name"
                      aria-label="view-btn"
                      onClick={() => {
                        setOpenMeeting(att);
                        handleClose();
                      }}
                    >
                      {att?.file_identifier}
                    </span>
                  )}
                  {!acceptedExt(att?.file_identifier) && (
                    <a
                      className="file-name"
                      aria-label="view-btn"
                      href={att?.url}
                      download={true}
                      style={{ color: 'inherit' }}
                    >
                      {att?.file_identifier}
                    </a>
                  )}
                  <span className="file-size">{att?.file_size}</span>
                </div>
                <div className="attachement-right">
                  <a
                    className="icon-wrap"
                    style={{ color: 'inherit' }}
                    onClick={() => downloadAttachments(att)}
                  >
                    <em className="be be-download"></em>
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {Boolean(openMeeting) && (
        <SpaceMeetingAttachmentDialog
          {...{
            openMeeting,
            handleModalClose,
            downloadAttachments,
            setOpenMeeting,
          }}
        />
      )}
    </div>
  );
}
