function GridViewIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 3.99805C2.5 3.16962 3.17157 2.49805 4 2.49805H7C7.82843 2.49805 8.5 3.16962 8.5 3.99805V6.99805C8.5 7.82647 7.82843 8.49805 7 8.49805H4C3.17157 8.49805 2.5 7.82647 2.5 6.99805V3.99805Z"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 12.998C2.5 12.1696 3.17157 11.498 4 11.498H7C7.82843 11.498 8.5 12.1696 8.5 12.998V15.998C8.5 16.8265 7.82843 17.498 7 17.498H4C3.17157 17.498 2.5 16.8265 2.5 15.998V12.998Z"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 3.99805C11.5 3.16962 12.1716 2.49805 13 2.49805H16C16.8284 2.49805 17.5 3.16962 17.5 3.99805V6.99805C17.5 7.82647 16.8284 8.49805 16 8.49805H13C12.1716 8.49805 11.5 7.82647 11.5 6.99805V3.99805Z"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 12.998C11.5 12.1696 12.1716 11.498 13 11.498H16C16.8284 11.498 17.5 12.1696 17.5 12.998V15.998C17.5 16.8265 16.8284 17.498 16 17.498H13C12.1716 17.498 11.5 16.8265 11.5 15.998V12.998Z"
        className="stroke"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GridViewIcon;
