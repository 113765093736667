import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { getFullname, getInitials } from '../util/util';
import OverflowTooltip from './OverflowTooltip';

const UserDropdown = ({ users }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = (e) => {
    e?.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div
      aria-label="see-more-users"
      className="initials"
      style={{ backgroundColor: `#ECEDF0` }}
      onClick={(event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
      }}
    >
      +{users?.length - 3}
      <Menu
        id="basic-menu"
        className="table-wrapper ideaboard-tooltip"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {users?.slice(3)?.map((user, idx) => (
          <MenuItem
            aria-label={`user-name-${idx}`}
            key={user?.id}
            onClick={(e) => e.stopPropagation()}
          >
            <em
              className="initials"
              style={{
                color: '#fff',
                backgroundColor: `#B388FF`,
                marginRight: '0.8rem',
              }}
            >
              {user?.profile_picture?.profile_picture_small ? (
                <img
                  src={user?.profile_picture?.profile_picture_small}
                  alt=""
                  className="initials"
                />
              ) : (
                (getInitials(
                  getFullname(user?.first_name, user?.last_name) ??
                    user?.name?.[0]
                ) ?? user?.email?.[0])
              )}
            </em>{' '}
            {getFullname(user?.first_name, user?.last_name) || user?.name ? (
              <OverflowTooltip
                text={
                  getFullname(user?.first_name, user?.last_name) ?? user?.name
                }
                tooltip={
                  getFullname(user?.first_name, user?.last_name) ?? user?.name
                }
                placement={'right'}
              />
            ) : (
              <OverflowTooltip text={user?.email} tooltip={user?.email} />
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default UserDropdown;
