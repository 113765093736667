import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import instance from '../../../shared/interceptor';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import {
  formatDate,
  globalError,
  setLoader,
  sortByDate,
} from '../../../shared/util/util';

function CreditHistoryDialog({
  openCreditRequestHistoryDialogue,
  handleClose,
  handleClickOpen2,
}) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);

  const [creditHistory, setCreditHistory] = useState(null);
  const [showEmptyState, setShowEmptyState] = useState(false);

  useEffect(() => {
    getCreditHistory();
  }, []);

  const getCreditHistory = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}/space_credit_requests`;
      let res = await instance.get(url);
      setCreditHistory(
        sortByDate(res['space_credit_requests'], 'created_at', 'Latest')
      );
      if (res && !res?.['space_credit_requests']?.length) {
        setShowEmptyState(true);
      } else {
        setShowEmptyState(false);
      }
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    handleClose();
  };

  //==============================================

  return (
    <Dialog
      open={openCreditRequestHistoryDialogue}
      onClose={handleModalClose}
      className="credit-history-modal"
      data-testid="credit-history-modal"
    >
      <DialogContent className="modal-body-wrap">
        <span
          data-testid="close-modal"
          className="modal-close be-close"
          onClick={handleClose}
        ></span>
        <div className="modal-body">
          <div className="modal-title">
            <h2>Credit request history</h2>
          </div>
          <div className="table-wrapper table-responsive credit-history-table">
            <TableContainer>
              <Table sx={{ minWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>DATE</TableCell>
                    <TableCell className="text-center">
                      CREDIT REQUESTED & NOTE
                    </TableCell>
                    <TableCell>STATUS</TableCell>
                    <TableCell>ACTION/DECISION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creditHistory?.map((item) => (
                    <TableRow aria-label="req-history-row" key={item?.id}>
                      <TableCell>
                        {formatDate(
                          item?.created_at,
                          false,
                          false,
                          'DD MMM YYYY'
                        )}
                      </TableCell>
                      <TableCell className="text-center">
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <span>
                              <strong>Note:</strong> {item?.request_note}
                            </span>
                          }
                          classes={{
                            popper: 'info-tooltip estimated-consuption-tooltip',
                          }}
                        >
                          <span className="link-text">
                            {item?.credit_requested}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <span className="tag-wrapper">
                          <Stack direction="row" spacing={1}>
                            <Chip
                              label={item?.status?.replace('_', ' ')}
                              sx={{
                                background:
                                  item?.status == 'pending'
                                    ? '#FFAB00'
                                    : item?.status == 'rejected'
                                      ? '#FF5546'
                                      : '#00C853',
                              }}
                            />
                          </Stack>
                        </span>
                      </TableCell>
                      <TableCell>
                        {item?.status == 'pending' && (
                          <Button
                            variant="contained"
                            className="xs-btn btn-transparent"
                            onClick={() => {
                              handleClickOpen2(item);
                              handleClose();
                            }}
                          >
                            Cancel request
                          </Button>
                        )}
                        {item?.status == 'rejected' && (
                          <Tooltip
                            arrow
                            placement="top"
                            title={item?.feedback}
                            classes={{
                              popper:
                                'info-tooltip estimated-consuption-tooltip',
                            }}
                          >
                            <span className="link-text last">Feedback</span>
                          </Tooltip>
                        )}
                        {item?.status == 'partially_approved' && (
                          <span className="plus">+{item?.credit_approved}</span>
                        )}
                        {item?.status == 'approved' && (
                          <span className="plus">+{item?.credit_approved}</span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    {showEmptyState && (
                      <TableCell
                        colSpan={4}
                        aria-label="no-data-row"
                        className="text-center pt-2"
                      >
                        No data available.
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CreditHistoryDialog;
