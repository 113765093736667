import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts/highstock';
import currentDateIndicator from 'highcharts/modules/current-date-indicator';
import { startTransition, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import images from '../../../assets/images';
import ArchiveGoalDialog from '../../../components/dialog/business-goals-dialogue/ArchiveGoalDialog';
import CreateBusinessGoalDialogue from '../../../components/dialog/business-goals-dialogue/CreateBusinessGoalDialog';
import WelcomeBusinessGoalsPopup from '../../../components/dialog/business-goals-dialogue/WelcomeBusinessGoalsPopup';
import DropdownIcon from '../../../components/utils/DropdownIcon';
import useWindowSize from '../../../shared/hooks/useWindowResize';
import instance from '../../../shared/interceptor';
import GoalsCard from '../../../shared/shared-comp/GoalsCard';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import OverflowTooltip from '../../../shared/shared-comp/OverflowTooltip';
import RightPageHeader from '../../../shared/shared-comp/RightPageHeader';
import {
  goalGraphState,
  setGoalsGraphReset,
} from '../../../shared/store/slices/goalSlice';
import {
  companyState,
  userState,
} from '../../../shared/store/slices/userSlice';
import { COMPANY, goalStatusList } from '../../../shared/util/constant';
import customPlugin from '../../../shared/util/customPlugin';
import {
  getColorCodeAndClassForGoals,
  globalError,
  sortByDate,
  transNavigation,
} from '../../../shared/util/util';
import { getAllCompanyGoals } from '../../dashboard/common/DashCommon';
import {
  goalsStatusData,
  goalsTimeLine,
} from '../../dashboard/dashboardGraphData';
import '../company.scss';
import './business-goals.scss';

currentDateIndicator(Highcharts);
customPlugin(Highcharts);
HighchartsMore(Highcharts);

export default function BusinessGoals() {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const userSel = useSelector(userState);
  const resetGraph = useSelector(goalGraphState);
  const location = useLocation();
  const [width, height] = useWindowSize();
  const navigate = useNavigate();

  const [companyGoals, setCompanyGoals] = useState(null);
  const [allGoalsArePrivate, setAllGoalsArePrivate] = useState(null);
  const [filterCompanyGoals, setFilterCompanyGoals] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [openEditDialogue, setOpenEditDialogue] = useState(false);
  const [sortBy, setSortBy] = useState('target');
  const [sortStatus, setSortStatus] = useState('all');
  const [welcomeGoalPopup, setWelcomeGoalPopup] = useState(false);
  const [activeTimelineData, setActiveTimelineData] = useState([]);
  const [goalNameList, setGoalNameList] = useState([]);
  const [archiveModal, setArchiveModal] = useState(null);
  const [goalDetail, setGoalDetail] = useState(null);
  const [showArchivedGoals, setShowArchivedGoals] = useState(false);
  const [random, setRandom] = useState(1);
  const [emptyCompanyGoals, setEmptyCompanyGoals] = useState(false);
  const [goalLoading, setGoalLoading] = useState(true);
  const [emptyGoal, setEmptyGoal] = useState(false);

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    setRandom((prev) => prev + 1);
  }, [width, height]);

  useEffect(() => {
    if (resetGraph && location?.pathname?.includes('business-goals')) {
      setRandom((prev) => prev + 1);
    }
    return () => {
      dispatch(setGoalsGraphReset(false));
    };
  }, [resetGraph]);

  const getAllGoals = () => {
    return getAllCompanyGoals(compSel);
  };

  const getStatusData = () => {
    const url = `${COMPANY}/${compSel?.id}/company_goals/status_data`;
    return instance.get(url);
  };

  const getTimelineData = () => {
    const url = `${COMPANY}/${compSel?.id}/company_goals/timeline_data`;
    return instance.get(url);
  };

  const getAllData = async () => {
    try {
      setGoalLoading(true);
      let res = await Promise.all([
        getAllGoals(),
        getStatusData(),
        getTimelineData(),
      ]);
      setCompanyGoals(
        sortByDate(res?.[0]?.['company_goals'], 'due_date', 'Oldest')
      );
      setFilterCompanyGoals(
        sortByDate(res?.[0]?.['company_goals'], 'due_date', 'Oldest')
      );
      setStatusData(res?.[1]);
      setGoalNameList(
        res?.[2]?.['company_goals'].map((item) => ({
          id: item?.id,
          name: item?.name,
          status: item?.status,
          archive: null,
          kpis: item?.kpis,
          private: item?.private,
        }))
      );
      if (res && !res?.[0]?.['company_goals']?.length) {
        setEmptyCompanyGoals(true);
      }
      // setTimelineData(res?.[2]?.['company_goals'].map(item => item?.kpis)?.flat()?.map((kpi, idx) => (
      //   {
      //     name: getColorCodeAndClassForGoals(kpi?.status, kpi?.archive ?? null)?.class,
      //     color: getColorCodeAndClassForGoals(kpi?.status, kpi?.archive ?? null)?.color,
      //     low: new Date(kpi?.created_at).getTime(),
      //     high: new Date(kpi?.due_date).getTime(),
      //     kpi_name: kpi?.name
      //   }
      // )))
      // sortByStatus({target: { value: sortStatus }})
      setSortStatus('all');
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setGoalLoading(false);
    }
  };

  useEffect(() => {
    createGraphData();
  }, [goalNameList]);

  const createGraphData = (goalId = null) => {
    setEmptyGoal(false);
    if (goalNameList) {
      let tempData;
      if (!goalId) {
        tempData = goalNameList?.[0]?.kpis?.flat();
      } else {
        tempData = goalNameList
          .filter((item) => item?.id == goalId)
          .map((item) => item?.kpis)
          ?.flat();
      }
      if (!tempData?.length) {
        setEmptyGoal(true);
        let d = new Date();
        setActiveTimelineData(
          Array(3)
            .fill(2)
            .map((num, idx) => {
              return {
                goalName: goalId
                  ? goalNameList?.find((item) => item?.id == goalId)?.name
                  : goalNameList[0]?.name,
                name: getColorCodeAndClassForGoals('not_started', null)?.class,
                color: '#ffffff',
                low: new Date(d.setMonth(d.getMonth() - 1)).getTime(),
                high: new Date(d.setMonth(d.getMonth() + num)).getTime(),
                kpi_name: ``,
              };
            })
        );
      } else {
        setActiveTimelineData(
          tempData?.map((kpi, idx) => ({
            goalName: goalId
              ? goalNameList?.find((item) => item?.id == goalId)?.name
              : goalNameList[0]?.name,
            name: getColorCodeAndClassForGoals(
              kpi?.status,
              kpi?.archive ?? null
            )?.class,
            color: getColorCodeAndClassForGoals(
              kpi?.status,
              kpi?.archive ?? null
            )?.color,
            low: new Date(kpi?.created_at).getTime(),
            high: new Date(kpi?.due_date).getTime(),
            kpi_name: kpi?.name,
          }))
        );
      }
      setRandom((prev) => prev + 1);
    }
  };

  const sortGoals = (e) => {
    setSortBy(e.target.value as any);
    if (e.target.value == 'target') {
      setCompanyGoals(sortByDate(filterCompanyGoals, 'due_date', 'Oldest'));
    } else {
      setCompanyGoals(sortByDate(filterCompanyGoals, 'created_at', 'Latest'));
    }
  };

  const sortByStatus = (e) => {
    let val = e?.target?.value;
    let search = searchText?.toLowerCase();
    setSortStatus(val);
    if (val == 'all') {
      setCompanyGoals(
        filterCompanyGoals.filter((item) =>
          item?.name?.toLowerCase().includes(search)
        )
      );
    } else {
      setCompanyGoals(
        filterCompanyGoals.filter(
          (goals) =>
            goals.status == val && goals?.name?.toLowerCase().includes(search)
        )
      );
    }
  };

  const filterGoals = (e) => {
    let value = e?.target?.value;
    setSearchText(value);
    if (!value) {
      setAllGoalsArePrivate(false);
      return setCompanyGoals(
        filterCompanyGoals.filter((item) => {
          if (sortStatus == 'all') {
            return item;
          } else {
            return item?.status == sortStatus;
          }
        })
      );
    }
    let filteredItems = filterCompanyGoals.filter((item) => {
      let nameVal = item?.name?.toLowerCase();
      if (sortStatus == 'all') {
        return nameVal.includes(value?.toLowerCase());
      } else {
        return (
          nameVal.includes(value?.toLowerCase()) && item?.status == sortStatus
        );
      }
    });

    let allFilteredGoalsPrivate = filteredItems.length > 0;
    filteredItems.map((item) => {
      allFilteredGoalsPrivate &&=
        item?.private && item?.owner?.id != userSel?.id;
    });
    setEmptyCompanyGoals(filteredItems.length === 0);
    setAllGoalsArePrivate(allFilteredGoalsPrivate);

    setCompanyGoals(filteredItems);
  };

  const handleClose = (e, render = null) => {
    setOpenCreateDialogue(false);
    setArchiveModal(null);
    setOpenEditDialogue(false);
    if (render) {
      getAllData();
    }
  };

  const editClickHandler = (goal) => {
    setOpenEditDialogue(true);
    setGoalDetail(goal);
  };

  const archiveClickHandler = (goal) => {
    setArchiveModal(goal);
  };

  //=======================================================================

  return (
    <div
      className="goals-overview-page"
      aria-label="business-goals-overview-page"
    >
      <div className="page-container top-space">
        <div className="main-page-header">
          <div className="page-breacrumb">
            <div className="breadcrumb-wrapper">
              <ul className="breadcrumb-links">
                <li>
                  <a
                    onClick={() =>
                      transNavigation(
                        startTransition,
                        navigate,
                        '/company/company-settings'
                      )
                    }
                  >
                    Company
                  </a>
                </li>
                <li>Business goals</li>
              </ul>
            </div>
            <RightPageHeader />
          </div>
          <div className="page-title">
            <h2>Business goals</h2>
          </div>
        </div>
        <div className="separator">&nbsp;</div>
        <div className="timeline-graph-wrap">
          <h4>Timeline</h4>
          {
            <div className="graph-container">
              <div
                style={{ width: '18%', marginTop: '0', marginRight: '1.6rem' }}
              >
                {goalLoading && (
                  <div
                    className="searching-loader"
                    style={{ height: '34vh', alignItems: 'center' }}
                  >
                    <div className="dot-flashing"></div>
                  </div>
                )}
                {!goalLoading && <h5>Goals</h5>}
                {activeTimelineData?.length > 0 && !goalLoading && (
                  <div
                    className="custom-scrollbar"
                    style={{
                      width: '100%',
                      marginTop: '0',
                      height: '31rem',
                      overflowY: 'scroll',
                      marginRight: '1.6rem',
                    }}
                  >
                    <span>
                      {goalNameList
                        ?.filter((item) => !item?.private)
                        ?.map((item) => (
                          <span
                            key={item?.id}
                            onClick={() => createGraphData(item?.id)}
                          >
                            <OverflowTooltip
                              placement={'top'}
                              style={{
                                backgroundColor:
                                  activeTimelineData[0]?.goalName == item?.name
                                    ? '#F3EBFF'
                                    : '#F9FAFB',
                                cursor: 'pointer',
                                height: '52px',
                                lineHeight: '52px',
                                textTransform: 'none',
                              }}
                              className={'label-box'}
                              tooltip={item?.name}
                              text={item?.name}
                              html={
                                <em
                                  className="color-dot"
                                  style={{
                                    backgroundColor:
                                      getColorCodeAndClassForGoals(
                                        item?.status,
                                        item?.archive
                                      )?.color,
                                  }}
                                ></em>
                              }
                            />
                          </span>
                        ))}
                    </span>
                  </div>
                )}
              </div>
              <div style={{ width: '80%', position: 'relative' }} key={random}>
                {goalLoading && (
                  <div
                    className="searching-loader"
                    style={{ height: '34vh', alignItems: 'center' }}
                  >
                    <div className="dot-flashing"></div>
                  </div>
                )}
                {!goalLoading && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    containerProps={{ className: emptyGoal ? 'blur' : '' }}
                    options={goalsTimeLine(activeTimelineData)}
                  />
                )}
                {!goalLoading && emptyGoal && (
                  <p className="empty-goal-content">
                    <strong>No KPI added for this goal yet</strong>
                  </p>
                )}
              </div>
            </div>
          }
        </div>

        {/* TODO: will be enable later */}
        {/* {!activeTimelineData?.length && <div className="no-data-wrapper">
            <em className="thumb"><img src={images.emptyStateIllustration} alt="Empty" /></em>
            <h4 aria-label="no-space">No KPIs yet</h4>
            <p>Add KPIs to measure your business goals</p>         
          </div>} */}

        <h2 className="section-title mb-0">All goals</h2>

        <div className="goals-container">
          <div className="goals-left-col">
            <div className="secondary-header">
              <div
                className={`page-filters-wrapper ${emptyCompanyGoals || allGoalsArePrivate ? 'disabled-filters' : ''}`}
              >
                <div className="left-filter">
                  <div className="form-group sort-by">
                    <div className="dropdown-wrapper">
                      <span className="dropdown-text">
                        Sort by &nbsp;&nbsp;&nbsp;<strong></strong>
                      </span>
                      <Select
                        IconComponent={DropdownIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          classes: { list: 'p-0' },
                        }}
                        className="select-icon borderless-dropdown sm-height sm-right no-label"
                        displayEmpty
                        value={sortBy}
                        onChange={sortGoals}
                      >
                        <MenuItem className="sorting-menu-item" value="target">
                          Target date
                        </MenuItem>
                        <MenuItem className="sorting-menu-item" value="created">
                          Created on
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="form-group sort-by">
                    <div className="dropdown-wrapper">
                      <span className="dropdown-text">
                        Show &nbsp;&nbsp;&nbsp;<strong></strong>
                      </span>
                      <Select
                        IconComponent={DropdownIcon}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          classes: { list: 'p-0' },
                        }}
                        className="select-icon borderless-dropdown sm-height sm-right no-label"
                        displayEmpty
                        value={sortStatus}
                        onChange={sortByStatus}
                      >
                        <MenuItem className="sorting-menu-item" value="all">
                          All
                        </MenuItem>
                        {goalStatusList.map((item) => (
                          <MenuItem
                            key={item?.id}
                            className="sorting-menu-item"
                            value={item?.value}
                          >
                            {item?.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="right-filter">
                  <Tooltip
                    placement="top"
                    arrow
                    title={`${showArchivedGoals ? 'Hide' : 'Show'} archive`}
                    classes={{ popper: 'info-tooltip' }}
                  >
                    <Button
                      variant="outlined"
                      className="xs-btn is-icon-btn be-archive"
                      onClick={() => setShowArchivedGoals((prev) => !prev)}
                      style={showArchivedGoals ? { background: '#F3EBFF' } : {}}
                    ></Button>
                  </Tooltip>
                  <Button
                    onClick={() => setOpenCreateDialogue(true)}
                    variant="contained"
                    className="xs-btn purple-btn btn-add-goal"
                  >
                    Add goal
                  </Button>
                </div>
              </div>
            </div>

            {/* TODO : add status class  on-track : #69F0AE, progressing : #FFD740, off-track : #FF8A80, accomplished : #00C853, accomplished2 : #83889E */}
            {goalLoading && (
              <LoaderComp border={true} style={{ height: '20vh' }} />
            )}
            {!goalLoading &&
              companyGoals?.length > 0 &&
              companyGoals?.map((goal) => {
                if (
                  (goal?.private && goal?.owner?.id != userSel?.id) ||
                  (!showArchivedGoals && goal?.archive)
                ) {
                  return null;
                } else {
                  return (
                    <GoalsCard
                      key={goal?.id}
                      {...{ goal, editClickHandler, archiveClickHandler }}
                    />
                  );
                }
              })}

            {(emptyCompanyGoals || allGoalsArePrivate) && (
              <div className="no-data-wrapper">
                <em className="thumb">
                  <img src={images.emptyStateIllustration} alt="Empty" />
                </em>
                <h4 aria-label="no-space">No goals yet</h4>
                <p>Add goals to keep your development on track.</p>
                <Button
                  onClick={() => setOpenCreateDialogue(true)}
                  variant="contained"
                  className="xs-btn grey-btn btn-add-goals"
                >
                  Add goal
                </Button>
              </div>
            )}
          </div>
          <div className="goals-right-col">
            <div className="search-wrap">
              <FormControl variant="filled">
                <FilledInput
                  type="text"
                  className="xs-input search-input-wrap search-goals"
                  placeholder="Search goals"
                  value={searchText}
                  startAdornment={
                    <InputAdornment position="start">
                      <em className="be-search"></em>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end" className="search-icon-wrap">
                      {searchText && (
                        <img
                          data-testid="cross-btn"
                          className="cr-pointer"
                          onClick={() => filterGoals({ target: { value: '' } })}
                          src={images.iconCrossCircle}
                          alt=""
                        />
                      )}
                    </InputAdornment>
                  }
                  inputProps={{ 'data-testid': 'filter-goals-input' }}
                  onChange={filterGoals}
                />
              </FormControl>
            </div>
            {goalLoading && (
              <LoaderComp border={true} style={{ height: '20vh' }} />
            )}
            {!goalLoading && (
              <div className="right-col-section">
                <div className="goal-count">
                  <span>{statusData?.total_count}</span> Goals
                </div>
                <div className="chart-wrapper">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={goalsStatusData(statusData, '210px', false, false)}
                  />
                </div>
                <div className="goals-status-wrap">
                  <div className="content-row title">
                    <div className="content-left">Status</div>
                    <div className="content-right">Goals</div>
                  </div>
                  {/* { onTrack: '#69F0AE' },{ progressing: '#FFD740' },{ offTrack: '#FF8A80' },{ accomplished: '#00C853' } */}
                  {goalStatusList
                    .filter((it) => it?.value)
                    ?.map((item) => (
                      <div className="content-row" key={item?.id}>
                        <div className="content-left">
                          <em
                            className="color-dot"
                            style={{
                              backgroundColor: item?.color,
                              border: item?.border,
                            }}
                          ></em>{' '}
                          {item?.text}
                        </div>
                        <div className="content-right">
                          {statusData?.[item?.value] ?? 0}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Welcome business goal popup */}
      {welcomeGoalPopup && (
        <WelcomeBusinessGoalsPopup {...{ setWelcomeGoalPopup }} />
      )}

      {/* Create business goal dialogue start */}
      {openCreateDialogue && (
        <CreateBusinessGoalDialogue {...{ openCreateDialogue, handleClose }} />
      )}
      {/* Create business goal dialogue end */}

      {openEditDialogue && (
        <CreateBusinessGoalDialogue
          {...{ openCreateDialogue: openEditDialogue, handleClose, goalDetail }}
        />
      )}

      {archiveModal && (
        <ArchiveGoalDialog {...{ archive: archiveModal, handleClose }} />
      )}
    </div>
  );
}
