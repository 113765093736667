import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import images from '../../../assets/images';
import instance from '../../../shared/interceptor';
import { getUserListWithoutPermission } from '../../../shared/services/common.service';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY, SPACES } from '../../../shared/util/constant';
import {
  debounce,
  getFullname,
  globalError,
  setLoader,
} from '../../../shared/util/util';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';

const CreateKpiGoalDialog = ({
  openDialogueKPI,
  handleClose,
  detail = null,
}) => {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const params = useParams();

  const [openCal, setOpenCal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [error, setError] = useState({
    name: '',
    desc: '',
    dueDate: '',
    spaceList: '',
    owner: '',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [spaceList, setSpaceList] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(
    detail ? detail?.spaces : []
  );
  const [formVal, setFormVal] = useState({
    name: detail?.name ? detail?.name : '',
    description: detail?.description ? detail?.description : '',
    due_date: detail?.due_date ? detail?.due_date : '',
    spaceList: detail?.space ? detail?.space : [],
    owner: detail?.owner,
  });
  const [searchSpaceList, setSearchSpaceList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openOwner, setOpenOwner] = useState(false);

  function datePickerIcon() {
    return <em className="icon be-meetings"></em>;
  }

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    handleClose(e, false);
  };

  const addToSelected = (value) => {
    let val = [...new Map(value.map((item) => [item['id'], item])).values()];
    setSelectedSpace(val);
  };

  const handleDelete = (idx) => {
    let tempArr = [...selectedSpace];
    tempArr.splice(idx, 1);
    setSelectedSpace(tempArr);
  };

  const searchSpace = (event) => {
    let val = event?.target?.value;
    setSearchQuery((prev) => val);
    setSpaceList(
      searchSpaceList?.filter((item) =>
        item.name.toLowerCase().includes(val.toLowerCase())
      )
    );
  };

  const getListOfSpaces = async () => {
    try {
      setLoading(true);
      setOpen(true);
      let url = `${SPACES.replace(':id', compSel?.id)}`;
      let spaceListRes = await instance.get(url);
      let filteredValue =
        spaceListRes?.['spaces'].filter(
          (item) => item.archive === false || item.private === false
        ) ?? [];
      setSpaceList(filteredValue);
      setSearchSpaceList(filteredValue);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
    }
  };

  const filterSpaceList = async (e) => {
    let val = e?.target?.value;
    if (val) {
      setSpaceList(
        searchSpaceList?.filter((item) =>
          item?.name?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    } else {
      setSpaceList(searchSpaceList);
    }
  };

  const getUserList = async (query) => {
    const userListRes = await getUserListWithoutPermission(
      compSel,
      query,
      dispatch,
      setLoading
    );
    setMemberList(userListRes?.['users'] ?? []);
  };

  const searchMembers = debounce((event) => {
    setOpenOwner(true);
    getUserList(event.target.value);
  });

  const checkFormVal = () => {
    if (!formVal?.name?.trim() || formVal?.name?.length > 50) {
      return {
        title: 'name',
        valid: false,
        message:
          'KPI name should not be empty and should not exceed 50 characters.',
      };
    } else if (
      !formVal?.description?.trim() ||
      formVal?.description?.length > 250
    ) {
      return {
        title: 'desc',
        valid: false,
        message:
          'KPI description should not be empty and should not exceed 250 characters.',
      };
    } else if (!formVal?.due_date) {
      return {
        title: 'dueDate',
        valid: false,
        message: 'Enter the target date.',
      };
    } else if (!selectedSpace?.length) {
      return {
        title: 'spaceList',
        valid: false,
        message: 'Select atleast a space.',
      };
    }
    return { valid: true };
  };

  const submitForm = async () => {
    try {
      let isFormValid = checkFormVal();
      if (!isFormValid?.valid) {
        return setError({
          ...error,
          [isFormValid['title']]: isFormValid?.message,
        });
      }
      resetErrorObj();
      setLoader(dispatch, true);
      let payload = {
        kpi: {
          ...formVal,
          owner_id: formVal?.owner?.id,
          due_date: moment(formVal?.due_date).format('YYYY-MM-DD'),
          space_ids: selectedSpace?.map((space) => space?.id),
        },
      };
      delete payload?.kpi?.spaceList;
      delete payload?.kpi?.owner;
      if (detail) {
        const url = `${COMPANY}/${compSel?.id}/company_goals/${params?.goal_id}/kpis/${detail?.id}`;
        await instance.patch(url, payload);
        dispatch(setSuccessMsg('KPI updated successfully.'));
      } else {
        const url = `${COMPANY}/${compSel?.id}/company_goals/${params?.goal_id}/kpis`;
        await instance.post(url, payload);
        dispatch(setSuccessMsg('KPI created successfully.'));
      }
      handleClose(null, true);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const resetErrorObj = () => {
    setError({
      name: '',
      desc: '',
      dueDate: '',
      spaceList: '',
      owner: '',
    });
  };

  //========================================================
  return (
    <Dialog
      open={openDialogueKPI}
      onClose={handleModalClose}
      className="business-goal-modal"
    >
      <DialogContent className="business-goal-body-wrap">
        <div className="modal-body" aria-label="create-kpi-dialog">
          <div className="modal-title">
            <h2>{detail ? 'Edit' : 'Create'} a KPI for your goal</h2>
          </div>
          <div className="modal-form mb-4">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl
                    className={`${error['name'] ? 'has-error' : ''}`}
                  >
                    <InputLabel>Type KPI...</InputLabel>
                    <FilledInput
                      inputProps={{ 'data-testid': 'form-name' }}
                      type="text"
                      placeholder=""
                      value={formVal?.name}
                      onChange={(e) => {
                        setFormVal((prev) => ({
                          ...prev,
                          name: e?.target?.value,
                        }));
                        resetErrorObj();
                      }}
                    />
                  </FormControl>
                  <span className="form-error">{error['name']}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <TextField
                    id=""
                    label="Description"
                    multiline
                    rows={4}
                    variant="outlined"
                    name="Description"
                    className={`${error['desc'] ? 'input-error' : ''} custom-textarea top-spacing`}
                    inputProps={{ 'data-testid': 'form-description' }}
                    value={formVal?.description}
                    onChange={(e) => {
                      setFormVal((prev) => ({
                        ...prev,
                        description: e?.target?.value,
                      }));
                      resetErrorObj();
                    }}
                  />
                  <span className="form-error">{error['desc']}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="date-picker-wrap">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        readOnly={!openCal}
                        open={openCal}
                        onClose={() => setOpenCal(false)}
                        format="DD/MM/YYYY"
                        disablePast
                        label="When should this KPI be completed?"
                        className={`${error['dueDate'] ? 'input-error' : ''} basic-date-picker`}
                        slots={{ openPickerIcon: datePickerIcon }}
                        slotProps={{
                          textField: {
                            size: 'small',
                            onClick: () => setOpenCal(true),
                            error: false,
                          },
                          popper: {
                            className: 'sm-datepicker-popper',
                          },
                        }}
                        value={moment(formVal?.due_date) ?? ''}
                        onChange={(e) => {
                          setFormVal((prev) => ({
                            ...prev,
                            due_date: e as any,
                          }));
                          resetErrorObj();
                        }}
                        data-testid="date-picker"
                      />
                    </LocalizationProvider>
                    <span className="form-error">{error['dueDate']}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <FormControl
                    variant="filled"
                    className={error['owner'] ? 'input-error' : ''}
                  >
                    <Autocomplete
                      selectOnFocus
                      clearOnBlur
                      data-testid="autocomplete-create-goal-search"
                      value={formVal?.owner}
                      id="searchMembersDropdown"
                      className={`${error['owner'] ? 'input-error' : ''} search-members-dropdown`}
                      classes={{ popper: 'search-dropdown-popper' }}
                      onChange={(event, value) => {
                        setFormVal((prev) => ({ ...prev, owner: value }));
                        resetErrorObj();
                      }}
                      filterOptions={(options) => options}
                      options={memberList}
                      autoHighlight
                      getOptionLabel={(option) =>
                        getFullname(option?.first_name, option?.last_name) ??
                        option?.email
                      }
                      open={openOwner}
                      onBlur={() => setOpenOwner(false)}
                      noOptionsText={
                        loading ? (
                          <LoaderComp
                            borderLessStyle={{
                              'max-width': '5%',
                              margin: '1rem 0.6rem',
                            }}
                          />
                        ) : (
                          <div style={{ margin: '0.3rem 0' }}>
                            No owner found
                          </div>
                        )
                      }
                      popupIcon={<em className="be-search"></em>}
                      renderOption={(props, option, { selected }) => (
                        <Box component="span" {...props} key={option?.id}>
                          <div className="search-members-dropdown">
                            <div className="left-side">
                              {option?.profile_picture
                                ?.profile_picture_small ? (
                                <img
                                  alt="profile-pic"
                                  className="initials"
                                  src={
                                    option?.profile_picture
                                      ?.profile_picture_small
                                  }
                                />
                              ) : (
                                <span className="initials">
                                  {option?.first_name?.[0] ?? '-'}
                                </span>
                              )}
                              <span className="user-description">
                                <span className="name">
                                  {getFullname(
                                    option?.first_name,
                                    option?.last_name
                                  ) ?? '-'}
                                </span>
                                <span className="email">({option?.email})</span>
                              </span>
                            </div>
                            <div className="right-side"></div>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            'data-testid': 'create-goal-text-field',
                          }}
                          className="search-input-box"
                          label="KPI owner"
                          variant="outlined"
                          onChange={(event) => searchMembers(event)}
                          onClick={(event) => searchMembers(event)}
                        />
                      )}
                    />
                  </FormControl>
                  <span className="form-error">{error['owner']}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="separator"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <h6>Assign this goal to spaces</h6>
                <div className="form-group searchbar-wrap">
                  <FormControl
                    className={error?.['spaceList'] ? 'input-error' : ''}
                  >
                    <Autocomplete
                      options={spaceList}
                      autoHighlight
                      multiple
                      disableClearable
                      disableCloseOnSelect
                      isOptionEqualToValue={(option, value) =>
                        option.id == value.id
                      }
                      filterOptions={(options) => options}
                      value={selectedSpace}
                      onChange={(event, value) => {
                        addToSelected(value);
                        resetErrorObj();
                      }}
                      open={open}
                      onBlur={() => setOpen(false)}
                      noOptionsText={
                        loading ? (
                          <LoaderComp
                            borderLessStyle={{
                              'max-width': '5%',
                              margin: '1rem 0.6rem',
                            }}
                          />
                        ) : (
                          <div style={{ margin: '0.3rem 0' }}>
                            No space found
                          </div>
                        )
                      }
                      renderTags={(value: string[], getTagProps) => ''}
                      getOptionLabel={(option) => option.name}
                      classes={{ popper: 'search-dropdown-popper' }}
                      className={`${error['spaceList'] ? 'input-error' : ''}`}
                      renderOption={(props, option, { selected }) => (
                        <Box component="span" {...props} key={option?.id}>
                          <div className="search-spaces-dropdown">
                            <div className="left-side">
                              <span
                                className="multiple-user-icon initials"
                                style={{ backgroundColor: `${option?.color}` }}
                              >
                                <em className="icon be-multiple-users"></em>
                              </span>
                              <span className="user-description">
                                <span className="name">{option?.name}</span>
                                {/* <span className='email'>{selectSpacesContent[0].email}</span> */}
                              </span>
                            </div>
                            <div className="right-side">
                              <Checkbox
                                className="square-checkbox"
                                checked={selected}
                                icon={
                                  <img src={images.checkboxUnchecked} alt="" />
                                }
                                checkedIcon={
                                  <img src={images.checkboxChecked} alt="" />
                                }
                              />
                            </div>
                          </div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            'data-testid': 'get-List-Of-Spaces',
                          }}
                          className="search-input-box"
                          label="Select spaces"
                          variant="outlined"
                          onChange={searchSpace}
                          onClick={getListOfSpaces}
                        />
                      )}
                    />
                  </FormControl>
                  <span className="form-error">{error['spaceList']}</span>
                </div>
                <div className="chips-wrapper list-items">
                  {/* <Stack direction="row" spacing={1}>
                    {selectedSpace.length > 0 && selectedSpace.map((item, idx) =>
                      <Chip key={item?.id} label={item?.name} deleteIcon={<span className='icon be-close-dark'></span>} onDelete={() => handleDelete(idx)} />
                    )}
                  </Stack> */}
                  <ul>
                    {selectedSpace?.length > 0 &&
                      selectedSpace.map(
                        (item, idx) => (
                          <li key={item?.id}>
                            <span>
                              <span
                                className="multiple-user-icon initials"
                                style={{ backgroundColor: `${item?.color}` }}
                              >
                                <em className="icon be-multiple-users"></em>
                              </span>
                              <span>{item?.name}</span>
                            </span>
                            <em
                              className="icon be-close"
                              onClick={() => handleDelete(idx)}
                            ></em>
                          </li>
                        )
                        // <li key={item?.id}>
                        // <span className='multiple-user-icon initials' style={{ backgroundColor: `${item?.color}` }}>
                        //     <em className='icon be-multiple-users'></em>
                        // </span>
                        // <span>{item?.name}</span> <em className="icon be-close" onClick={() => handleDelete(idx)}></em></li>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            data-testid="close-dialog"
            variant="contained"
            className="sm-btn grey-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="create-edit-kpi"
            variant="contained"
            className="sm-btn green-btn"
            onClick={submitForm}
          >
            {detail ? 'Save' : 'Create'} KPI
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CreateKpiGoalDialog;
